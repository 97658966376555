import React from 'react'
import { Route, HashRouter } from 'react-router-dom'
import '../Whole.scss'
import './Experiences.scss'
// import images from './certs/logo_dummy.png'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import logo_issa from './logos/logo-issa.png'
import logo_fipe from './logos/logo-fipe.png'
import logo_isci from './logos/logo-isci.png'
import logo_ncs from './logos/logo-ncs.png'
import logo_nsca from './logos/logo-nsca-cpt2.png'
import logo_open from './logos/logo-open.jpg'

import logo_nsca1 from './logos/logo-nsca.png'
import logo_nsca2 from './logos/logo-cscs.png'
import ReactGA from 'react-ga'

class Experiences extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickFipe = this.handleClickFipe.bind(this)
    this.handleClickIsci = this.handleClickIsci.bind(this)
    this.handleClickIssa = this.handleClickIssa.bind(this)
    this.handleClickNcs = this.handleClickNcs.bind(this)
    this.handleClickNsca = this.handleClickNsca.bind(this)
    this.handleClickNsca1 = this.handleClickNsca1.bind(this)
    this.handleClickNsca2 = this.handleClickNsca2.bind(this)
    this.handleClickOpen = this.handleClickOpen.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleClickFipe(e, history) {
    history.push('/experiences/fipe')
  }

  handleClickIsci(e, history) {
    history.push('/experiences/isci')
  }

  handleClickIssa(e, history) {
    history.push('/experiences/issa')
  }

  handleClickNcs(e, history) {
    history.push('/experiences/ncs')
  }

  handleClickNsca(e, history) {
    history.push('/experiences/nsca')
  }

  handleClickNsca1(e, history) {
    history.push('/experiences/nsca1')
  }

  handleClickNsca2(e, history) {
    history.push('/experiences/nsca2')
  }

  handleClickOpen(e, history) {
    history.push('/experiences/open')
  }

  render() {
    return (
      <Route render={({ history }) => (
        <div className="background">
          <MDBContainer>
            <label className="title text-center white-text pt-4">
              CERTIFICAZIONI
            </label>
            <MDBRow>
              <MDBCol sm="6">
                <div className="cont-logos">
                  <div style={{ backgroundImage: `url(${logo_isci})` }}
                    onClick={(e => this.handleClickIsci(e, history))}>
                  </div>
                </div>
              </MDBCol>

              <MDBCol sm="6">
                <div className="cont-logos">
                  <div style={{ backgroundImage: `url(${logo_issa})` }}
                    onClick={(e => this.handleClickIssa(e, history))}>
                  </div>
                </div>
              </MDBCol>

              <MDBCol sm="6">
                <div className="cont-logos">
                  {/* <div style={{ backgroundImage: `url(${logo_nsca})` }}
                    onClick={(e => this.handleClickNsca(e, history))}>
                  </div> */}
                  <img className="img-fluid mouse" src={logo_nsca1} alt=""
                    onClick={e => this.handleClickNsca1(e, history)} />
                  <img className="img-fluid mouse" src={logo_nsca2} alt=""
                    onClick={e => this.handleClickNsca2(e, history)} />
                </div>
              </MDBCol>

              <MDBCol sm="6">
                <div className="cont-logos">
                  <div style={{ backgroundImage: `url(${logo_open})` }}
                    onClick={(e => this.handleClickOpen(e, history))}>
                  </div>
                </div>
              </MDBCol>

              <MDBCol sm="6">
                <div className="cont-logos">
                  <div style={{ backgroundImage: `url(${logo_ncs})` }}
                    onClick={(e => this.handleClickNcs(e, history))}>
                  </div>
                </div>
              </MDBCol>

              <MDBCol sm="6">
                <div className="cont-logos">
                  <div style={{ backgroundImage: `url(${logo_fipe})` }}
                    onClick={(e => this.handleClickFipe(e, history))}>
                  </div>
                </div>
              </MDBCol>

            </MDBRow>
          </MDBContainer>
        </div>
      )} />
    )
  }
}


export default Experiences