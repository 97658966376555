import React from 'react';
import '../Whole.scss'
import './About.scss'
import ste_2016_rimini_1 from './ste_2016_rimini_1.jpg'
import ste_2016_rimini_2 from './ste_2016_rimini_2.jpg'
import ste_2016_rimini_3 from './ste_2016_rimini_3.jpg'
import ste_2016_rimini_4 from './ste_2016_rimini_4.jpg'
import ste_2016_rimini_5 from './ste_2016_rimini_5.jpg'
import ste_2009_avezzano_1 from './ste_2009_avezzano_1.jpg'
import ste_2009_avezzano_2 from './ste_2009_avezzano_2.jpg'
import ste_2009_avezzano_3 from './ste_2009_avezzano_3.jpg'
import ste_2009_avezzano_4 from './ste_2009_avezzano_4.jpg'
import ste_2007_1 from './ste_2007_1.jpg'
import ste_2007_2 from './ste_2007_2.jpg'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { connect } from 'react-redux'
import { toggleModal, changeModalImage } from '../store/actions'
import ReactGA from 'react-ga'

class About extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              CHI SONO
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              Ciao,
<br />Mi chiamo Stefano e nella vita ho la fortuna di fare quello che mi piace… Ho iniziato
ad appassionarmi alla trasformazione del corpo all’età di 13 anni e da allora non ho
mai smesso, fino al punto di averla fatta diventare la mia attività.
<br />Da ormai 17 anni svolgo la professione di personal trainer. Iniziai nei primi anni del
2000 con delle sostituzioni in palestra per poi lavorarci a tempo pieno nella
primavera del 2003.
<br />La cosa che più mi piace del mio lavoro è che si impara ogni giorno da chiunque, sia
sull’aspetto umano che per quanto riguarda quello tecnico. In tutti questi anni ho
sempre tenuta viva la mia passione con continui stimoli ed aggiornamenti.
<br />Ho la fortuna di spaziare dalla preparazione atletica a corsi per la terza età, da corsi
riguardanti l’aspetto posturale o prestazionale con l’utilizzo dei “bosu” – attrezzo
maggiormente usato per la presciistica - a ore individuali con l’utilizzo del
“reformer” e della “ladder barrel” del pilates. E ancora, dall’insegnamento di
discipline come il “nordic walking” o il “brisk walking” a sessioni individuali di
personal training con gli obiettivi più diversi: aumento della massa magra, perdita
della massa grassa, preparazioni per prestazione sportive anche per atleti disabili di
hand bike, innalzamento prestazionale per l’ammissione a test per le forze armate o
ricondizionamento muscolare post-fisioterapico.
<br />Il mio percorso di studi iniziò nel lontano 1998 per pura cultura personale: ero
stanco di avere risposte sbagliate alle mie domande sull’allenamento.
Presi in poco tempo i tre livelli del BIO di Claudio Tozzi per poi raggiungere la
certificazione “ISSA CFT 3” e “ISSA CFT 2”, mentre nel 2009 presi la certificazione del
“TUDOR BOMPA INSTITUTE PPS” come esperto nella programmazione e
periodizzazione di piani di allenamento per sport individuali o di squadra. Nel 2012
presi la certificazione “N.S.C.A CPT”. Tra il 2013 e il 2017 conseguii le seguenti
certificazioni “I.S.C.I”(International Strength Conditioning Institute): RTS come
specialista in tecniche di recupero e rigenerazione, SCS specialista in velocità e
resistenza, SSC allenatore della forza per lo sport, SNSE esperto in nutrizione ed
integrazione per lo sport. Con la “STRENGTH ACADEMY della FEDERAZIONE
ITALIANA PESISTICA”mi certificai con il diploma di istruttore personal trainer senior.
<br />Nel 2019 ho conseguito sempre con la “STRENGTH ACADEMY la certificazione di
“Personal Strength Coach Master Sport Specialist” e nel 2020 la certificazione
internazionale della “N.S.C.A CSCS” come esperto per il condizionamento della forza
per atleti di sport di squadra o individuali.
<br />Nella vita mi piace mantenermi in forma con i pesi e con la bici da corsa,
completando la settimana con almeno due sedute di pilates con l’utilizzo del
“reformer”. Ogni tanto, per mettermi alla prova e pormi delle sfide, mi diverto con
qualche gara “Natural” di Bodybuilding.
<br />Nel mio lavoro mi piace ASCOLARE, PERCEPIRE E VISUALIZZARE per poter poi arrivare
alla conquista del risultato… all’obiettivo, alla meta che il mio cliente si prefissa!
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
      </MDBContainer>
      <hr className="border border-dark mx-5" />
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12" className="pt-4 pb-2">
            <label className="title text-center white-text">
              Passione
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              Mi e’ sempre piaciuta la fisicità! Sin da quando ero piccolo ne sono sempre stato
              attratto tanto che già a 13 anni ho cominciato a muovere i primi bilancieri, facendo
              poi diventare gli allenamenti più assidui e seri intorno ai 19 anni! Per quanto
              riguarda le gare di body building ho fatto la prima nel 2007, all’età di 33 anni. Le
              considero un mezzo per imparare ulteriormente nozioni da trasmettere ai miei
              clienti e, perché no, per migliorare il fisico, dato che solo se ci si prefigge una meta si
              riesce ad arrivare a superare ostacoli insormontabili! È per questo che ancora oggi
              gareggio solo per il gusto di farlo e solo quando ne ho veramente la voglia… Deve
              essere un divertimento e non un sacrificio e l’alimentazione la fa da padrone. A
              seguire le foto della mia prima gara nel 2007 fatta con IFBB in Piemonte, la seconda
              nel 2009 campionati italiani ad AVEZZANO fatta con AINBB e nel 2016 i campionati
              italiani a Rimini sempre con AINBB! Consigli per una gara… essere veramente
              determinati e farlo per se stessi… cercando di migliorarsi… e imparare!
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-center white-text">
              CAMPIONATO ITALIANO AINBB - RIMINI 2016
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2016_rimini_1} alt=""
              onClick={(e => this.handleClick(e, ste_2016_rimini_1))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2016_rimini_2} alt=""
              onClick={(e => this.handleClick(e, ste_2016_rimini_2))} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2016_rimini_3} alt=""
              onClick={(e => this.handleClick(e, ste_2016_rimini_3))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2016_rimini_4} alt=""
              onClick={(e => this.handleClick(e, ste_2016_rimini_4))} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2016_rimini_5} alt=""
              onClick={(e => this.handleClick(e, ste_2016_rimini_5))} />
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-center white-text">
              CAMPIONATO ITALIANO AINBB - AVEZZANO 2009
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2009_avezzano_1} alt=""
              onClick={(e => this.handleClick(e, ste_2009_avezzano_1))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2009_avezzano_2} alt=""
              onClick={(e => this.handleClick(e, ste_2009_avezzano_2))} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2009_avezzano_3} alt=""
              onClick={(e => this.handleClick(e, ste_2009_avezzano_3))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2009_avezzano_4} alt=""
              onClick={(e => this.handleClick(e, ste_2009_avezzano_4))} />
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-center white-text">
              IFBB 2007 - Piemonte
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2007_1} alt=""
              onClick={(e => this.handleClick(e, ste_2007_1))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="contest img-fluid" src={ste_2007_2} alt=""
              onClick={(e => this.handleClick(e, ste_2007_2))} />
          </MDBCol>
        </MDBRow>
        <br />
        <br />
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(About)