import React from 'react';
import { Route, HashRouter } from 'react-router-dom'
import '../Whole.scss';
import './Services.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import service_tile_bodybuilding from './service_tile_bodybuilding.jpg'
import service_tile_calcio from './service_tile_calcio.jpg'
import service_tile_handbike from './service_tile_handbike.jpg'
import service_tile_nordic from './service_tile_nordic.jpg'
import service_tile_sci from './service_tile_sci.jpg'
import service_tile_slowtourism from './service_tile_slowtourism.jpg'
import service_tile_maratona from './service_tile_maratona.jpg'

class Pianificazione extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickBodybuilding = this.handleClickBodybuilding.bind(this)
    this.handleClickHandbike = this.handleClickHandbike.bind(this)
    this.handleClickSci = this.handleClickSci.bind(this)
    this.handleClickNordic = this.handleClickNordic.bind(this)
    this.handleClickMaratona = this.handleClickMaratona.bind(this)
    this.handleClickSlowtourism = this.handleClickSlowtourism.bind(this)
    this.handleClickCalcio = this.handleClickCalcio.bind(this)
  }

  handleClickBodybuilding(e, history) {
    history.push('/services/pianificazione/bodybuilding')
  }

  handleClickHandbike(e, history) {
    history.push('/services/pianificazione/handbike')
  }

  handleClickSci(e, history) {
    history.push('/services/pianificazione/sci')
  }

  handleClickNordic(e, history) {
    history.push('/services/pianificazione/nordic')
  }

  handleClickMaratona(e, history) {
    history.push('/services/pianificazione/maratona')
  }

  handleClickSlowtourism(e, history) {
    history.push('/services/pianificazione/slowtourism')
  }

  handleClickCalcio(e, history) {
    history.push('/services/pianificazione/calcio')
  }

  render() {
    return <div className="background">
      <Route render={({ history }) => (
        <div>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12">
                <label className="title text-center white-text pt-4">
                  PIANIFICAZIONE SPORT
      </label>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol sm="12">
                <p className="text-justify white-text">
                  La sezione “pianificazione sport” spiega – con l’utilizzo di una semplice tabella che illustra la pianificazione annuale -  (quelle raffigurate vogliono essere solo un esempio dato che ognuno ha le proprie caratteristiche fisiche e la propria storia e  non ci saranno mai due pianificazioni identiche) come si possa programmare un piano annuale con l’integrazione delle abilità biomotorie sia che si partecipi ad una competizione, sia che si voglia anche solo affrontare la stagione sciistica con una buona performance, abbassando il rischio di infortuni.
<br /><br />La periodizzazione permette di avere un’alternanza di volume e intensità in base al periodo che si affronta, suddividendo una fase preparatoria generale  - dove si lavorerà maggiormente su un adattamento anatomico con volumi maggiori - per poi passare al periodo di preparazione specifica con volumi minori e maggiore intensità e specificita’ dei mezzi allenanti.
<br /><br />Ci sara’ poi il periodo competitivo dove si raggiungerà il picco di performance, giocando sulle variabili di volume ed intensità e sull’alternanza delle abilità e qualità biomotorie dello sport in questione. In ultimo un periodo di transizione dove si permetterà un completo recupero al fisico e alla testa.
<br /><br />Sotto ad ogni piano annuale trovate il modello prestativo dello sport in questione, una sorta di analisi dettagliata che permette di centrare l’obiettivo della disciplina analizzando nel profondo: il sistema energetico dominante, la percentuale dell’ergogenesi (suddivisione in percentuale della dominanza dei 3 sistemi energetici), i fattori limitanti (significa che la prestazione non sarà ottimale se i fattori elencati non verrano sviluppati durante la preparazione) e i substrati energetici, ovvero effetti di energia chimica derivante dalla demolizione e ossidazione di diverse sostanze quali carboidrati, proteine, grassi (fosfocreatina-glicogeno-acidi grassi).
<br /><br />In sequenza troverete gli sport e le discipline da me più trattati. Posso comunque creare una pianificazione annuale per qualsiasi sport e disciplina.
      </p>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickBodybuilding(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_bodybuilding} alt="" />
                  <br />
                  <h4 className="text-center white-text">Bodybuilding</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickHandbike(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_handbike} alt="" />
                  <br />
                  <h4 className="text-center white-text">Handbike</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickSci(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_sci} alt="" />
                  <br />
                  <h4 className="text-center white-text">Sci</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickNordic(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_nordic} alt="" />
                  <br />
                  <h4 className="text-center white-text">Nordic</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickMaratona(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_maratona} alt="" />
                  <br />
                  <h4 className="text-center white-text">Maratona</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickSlowtourism(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_slowtourism} alt="" />
                  <br />
                  <h4 className="text-center white-text">Slow Tourism</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse"
                  onClick={e => this.handleClickCalcio(e, history)}>
                  <img className="img-fluid rounded-circle" src={service_tile_calcio} alt="" />
                  <br />
                  <h4 className="text-center white-text">Calcio Under 15</h4>
                </div>
              </MDBCol>
            </MDBRow>
            <br />
          </MDBContainer>
        </div>
      )} />
    </div>
  }
}

export default Pianificazione