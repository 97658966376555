import React from 'react';
import '../Whole.scss'

class Event extends React.Component {
  render() {
    return <div className="background">
      <h2 className="text-center white-text pt-4">
        EVENTO
  </h2>
      <p className="text-center white-text">
        Non ci sono eventi in programma
  </p>
    </div>
  }
}

export default Event