import React from 'react';
import { Route, HashRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggle } from '../store/actions'
import { MDBBtn, MDBIcon } from 'mdbreact';

import TopbarMenuCert from './TopbarMenuCert'
import TopbarMenuServ from './TopbarMenuServ'

import './Topbar.scss'

class Topbar extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickHome = this.handleClickHome.bind(this)
    this.handleClickAbout = this.handleClickAbout.bind(this)
    this.handleClickExperiences = this.handleClickExperiences.bind(this)
    this.handleClickServices = this.handleClickServices.bind(this)
    this.handleClickPublications = this.handleClickPublications.bind(this)
    this.handleClickTheysay = this.handleClickTheysay.bind(this)
    this.handleClickContacts = this.handleClickContacts.bind(this)
    this.handleClickEvent = this.handleClickEvent.bind(this)
  }

  handleClickHome(e, history) {
    history.push('/home')
  }
  handleClickAbout(e, history) {
    history.push('/about')
  }
  handleClickExperiences(e, history) {
    history.push('/experiences')
  }
  handleClickServices(e, history) {
    history.push('/services')
  }
  handleClickPublications(e, history) {
    history.push('/publications')
  }
  handleClickTheysay(e, history) {
    history.push('/theysay')
  }
  handleClickContacts(e, history) {
    history.push('/contacts')
  }
  handleClickEvent(e, history) {
    history.push('/event')
  }

  handleClickAlignJustify(e) {
    this.props.toggle()
  }

  handleClickToggleSubmenuCert(e) {
    this.props.toggleSubmenuCert()
  }

  render() {
    return (
      <header className="topbar">
        <Route render={({ history }) => (
          <div className="enough-width">
            < ul >
              <li>
                <label onClick={(e => this.handleClickHome(e, history))}>
                  HOME
                </label>
              </li>
              <li>
                <label onClick={(e => this.handleClickServices(e, history))}>
                  SERVIZI
                </label>
                <TopbarMenuServ />
              </li>
              <li>
                <label onClick={(e => this.handleClickExperiences(e, history))}>
                  CERTIFICAZIONI
                </label>
                <TopbarMenuCert />
              </li>
              <li>
                <label onClick={(e => this.handleClickPublications(e, history))}>
                  DIVULGAZIONE
                </label>
              </li>
              <li>
                <label onClick={(e => this.handleClickAbout(e, history))}>
                  CHI SONO
                </label>
              </li>
              <li>
                <label onClick={(e => this.handleClickTheysay(e, history))}>
                  DICONO DI ME
                </label>
              </li>
              <li>
                <label onClick={(e => this.handleClickContacts(e, history))}>
                  CONTATTI
                </label>
              </li>
              {/* <li>
                <label onClick={(e => this.handleClickEvent(e, history))}>
                  EVENT
                </label>
              </li> */}
            </ul>
          </div>
        )
        } />

        < div className="limited-width" >
          <MDBBtn color="dark" className="topbar-button" onClick={(e => this.handleClickAlignJustify(e))}>
            <MDBIcon icon="bars" className="fa-3x" />
          </MDBBtn>
        </div >
      </header >
    )
  }
}

const mapDispatchToProps = {
  toggle
}

export default connect(null, mapDispatchToProps)(Topbar)