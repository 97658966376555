import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import handbike1 from './handbike1.jpg'
import handbike2 from './handbike2.jpg'

class Handbike extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>

        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Handbike
            </label>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={handbike1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Handbike e’ un modo di utilizzare la bici con il solo movimento delle braccia,quindi una bici a tutti gli effetti a 3 ruote che da’ la possibilita a una persona con una lesione spinale e non,di sentire e risentire l’aria sulla faccia!
<br />Tutto lo sforzo avviene dagli arti superiori,lo sforzo viene trasferito dalle manovelle e a sua volta alla catena,che fa girare la ruota anteriore,il principio e’ lo stesso di una comune bicicletta.
<br />Le 2 ruote posteriori permettono equilibrio e stabilita’che la bicicletta normale ovviamente non ha.
<br />L’handbike ha la possibilita’di adattare l’atleta affetto da diverse disabilita’:dalla parablegia,alla tetraplegia(non grave),all’amputazione agli arti inferiori,sopra o sotto al ginocchio.
<br />C’e’ una differenza sostanziale di posizione tra un’atleta amputato e un’atleta con lesione midollare.
<br />L’atleta amputato che verra’ classificato in H5 avra’ una posizione non sdraiata ma verticale con la possibilita’di sfruttare la flessione e l’estensione del tronco e glutei, non avendo lesioni midollari,ma con la penalizzazione della resistenza all’aria,l’atleta con lesione midollare avra’ una posizione sdraiata,quindi una resistenza all’aria minore e non avra’la possibilita’di sfruttare i muscoli del core per la dinamica motoria.
<br />Altra differenza sostanziale saranno gli RPM(rivoluzioni di pedalate al minuto),l’atleta amputato ne potra’ fare meno e avra’ bisogno di una riserva di forza piu’alta rispetto all’atleta con lesione midollare che potra’ sfrutare maggiormente il sistema cardiovascolare dato le elevate RPM al minuto.
<br />Dopo qualche anno di preparazione in gara nelle categorie H2 H3 i migliori riescono a raggiungere velocita’ che sfiorano i 40 km/h su percorsi pianeggianti,avendo pochissima resistenza all’aria dovuta alla posizione,anche se non sfruttano le grandi masse muscolari del tronco,le cose cambiano quando i percorsi cominciano a diventare leggermente collinari,gli atleti H5 utilizzando il busto e quindi piu’ muscoli, incontreranno meno fatica e soprattutto riusciranno a raggiungere velocita’ maggiori in volata.
<br />Le categorie per le gare sono quattro,tre nella posizione sdraiata con lesioni midollari dorsale e lombari,e  lesioni a livello cervicale,qui le prestazioni si abbassano raggiungendo velocita’ intorno a 25 km/h,molto al di sotto di lesioni dorsali e lombari,poi la categoria degli amputati in H 5.
<br />Quando parte il periodo competitivo intorno a fine marzo inizio aprile gli atleti hanno una gara a settimana circa,con un periodo di stop per il mese di agosto.
<br />Le gare sono normalmente di un ora piu’ un giro, con dislivelli di ascesa minimi e con kilometraggi intorno ai 40 km,ci sono anche delle cronometro della durata di 20 minuti ed alcune volte delle cronoscalate,ma proprio per la difficolta’ che gli atleti incontrano sono al massimo una o due di tutte le gare annuali.
<br />Le bici sono dotate ormai di gps e cardiofrequenzimetro e per quelli che si possono permettere qualcosa in piu’anche di rilevatore di potenza.
<br />Proprio per la poca richiesta il prezzo di questi gioiellini e’ molto alto,le top di gamma si aggirano intorno ai 10.000 euro se no di piu,chiaramente con telai in carbonio,cerchi a profilo alto,cambi elettronici e super leggere.
<br />Nella griglia di pianificazione annuale sotto esposta è raffigurata la programmazione di forza e resistenza aerobica di un mio ex atleta stagione 2017.
<br />Si inizia dalla preparazione invernale in palestra,con la suddivisione delle varie componenti della forza  e gli esercizi al rafforzamento dell’articolzione della spalla,molto sollecitata in questo sport per arrivare all’avvio della stagione intorno a marzo/aprile ed infine alla conclusione delle gare a ottobre con il periodo di transizione.
<br /><br />Di seguito una pianificazione annuale della stagione 2017 di un mio ex atleta(tratta dalla tesina “hanbbike”che potete trovare sul pannello materiale informativo.
      </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={handbike2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              LEGENDA
              <br />MXS: FORZA
              <br />P-RESIST: POTENZA RESISTENTE
              <br />MEM: RESISTENZA MUSCOLARE DI CORTA DURATA
              <br />MEL: RESISTENZA MUSCOLARE DI LUNGA DURATA
              <br />VEL MAX: VELOCITA’MASSIMA
              <br />TRAN: TRANSIZIONE
              <br /><br />MODELLO PRESTATIVO
              <br />SISTEMI ENERGETICI: SISTEMA AEROBICO 95%(DOMINANTE) 5% ANAEROBICO
              <br />SUBSTRATI ENERGETICI PRINCIPALI: GLICOGENO,ACIDI GRASSI LIBERI
              <br />FATTORI LIMITANTI: RESISTENZA MUSCOLARE DI LUNGA DURATA,POTENZA RESISTENTE
              <br />OBIETTIVI DELL’ALLENAMENTO: RESISTENZA MUSCOLARE DI LUNGA DURATA,POTENZA RESISTENTE,FORZA MASSIMA
            </p>
          </MDBCol>
        </MDBRow>

      </MDBContainer>
      <br />
    </div>
  }
}

export default Handbike