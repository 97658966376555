import React from 'react';
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import {
  MDBContainer, MDBBtn, MDBModal,
  MDBModalBody, MDBModalFooter, MDBIcon
} from 'mdbreact'
import './Topbar.scss'
import { hideSubmenuServ, toggleSubmenuServ } from '../store/actions'

class TopbarMenuServ extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClickToggle = this.handleClickToggle.bind(this)
    this.refSubmenu = React.createRef()
    this.handleClickPianificazione = this.handleClickPianificazione.bind(this)
    this.handleClickPersonal = this.handleClickPersonal.bind(this)
    this.handleClickPilates = this.handleClickPilates.bind(this)
    this.handleClickAnalisi = this.handleClickAnalisi.bind(this)
  }

  handleClickOutside(event) {
    if (this.refSubmenu.current && !this.refSubmenu.current.contains(event.target)) {
      this.props.hideSubmenuServ()
    }
  }

  handleClickToggle(event) {
    this.props.toggleSubmenuServ()
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickPianificazione(e, history) {
    history.push('/services/pianificazione')
    this.props.hideSubmenuServ()
  }

  handleClickPersonal(e, history) {
    history.push('/services/personal')
    this.props.hideSubmenuServ()
  }

  handleClickPilates(e, history) {
    history.push('/services/pilates')
    this.props.hideSubmenuServ()
  }

  handleClickAnalisi(e, history) {
    history.push('/services/analisi')
    this.props.hideSubmenuServ()
  }

  render() {
    return (
      <Route render={({ history }) => (
        <div ref={this.refSubmenu}>
          <div className="submenu">
            <div className="submenu-arrow">
              <MDBIcon icon="angle-down" className="mouse" onClick={(e => this.handleClickToggle(e))} />
            </div>
            {
              this.props.submenuServOpen
                ?
                <div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickPianificazione(e, history))}> Pianificazione Sport </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickPersonal(e, history))}> Personal Training </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickPilates(e, history))}> Pilates </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickAnalisi(e, history))}> Analisi </label>
                  </div>
                </div>
                :
                null
            }
          </div>

        </div>

      )}
      />
    )

  }
}

const mapStateToProps = state => {
  return {
    submenuServOpen: state.submenuServOpen
  }
}

const mapDispatchToProps = {
  hideSubmenuServ,
  toggleSubmenuServ
}


export default connect(mapStateToProps, mapDispatchToProps)(TopbarMenuServ)