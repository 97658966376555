import React from 'react';
import { MDBBtn } from 'mdbreact';
import '../Whole.scss'
import './LastUpdate.scss'
import { connect } from 'react-redux'
import { hideLastUpdate } from '../store/actions'

class LastUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    this.props.hideLastUpdate()
  }

  render() {
    return (
      <div className={
        'last-update ' +
        (this.props.lastUpdateOpen ? '' : 'hide')
      }>
        <p>Last Update 07-09-2020</p>
        <MDBBtn color="elegant" onClick={(e => this.handleClick(e))}>
          X</MDBBtn>
      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    lastUpdateOpen: state.lastUpdateOpen
  }
}

const mapDispatchToProps = {
  hideLastUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(LastUpdate)