import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import plico1 from './plico1.jpg'
import plico2 from './plico2.jpg'

class Plicometria extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              PLICOMETRIA
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              La plicometria è una tecnica di analisi che - con l’uso di un calibro che misura la pliche cutanea e l’applicazione di adeguate equazioni - permette di stimare la percentuale di massa grassa e massa magra (parte muscolare). E’ un’analisi quantitativa e bi-compartimentale dato che riesce a stimare solo due comparti: grasso (solo il sottocutaneo) e muscolo. In questo si differenzia dalla bioimpedenza (BIA) che è una analisi, invece, qualitativa e  tri-compartimentale (acqua intra ed extracellulare, massa grassa e massa magra).
<br />E’ una tecnica, comunque, utile perché si possono incrociare i dati con la BIA e consentire di constatare se ci sono stati degli accumuli di grasso sottocutaneo localizzati in distretti  corporei rispetto ad altri, apportando, se occorre, delle modifiche sull’allenamento, l’alimentazione,lo stile di vita o l’integrazione.
<br /><br />Di seguito un esempio di plicometria e l’estrapolamento del somatotipo:
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={plico1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={plico2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Plicometria

