import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import cammino1 from './cammino_1.jpg'
import cammino2 from './cammino_2.jpg'
import cammino3 from './cammino_3.jpg'
import cammino4 from './cammino_4.jpg'
import cammino_santiago1 from './cammino_santiago_1.jpg'
import cammino_santiago2 from './cammino_santiago_2.jpg'
import cammino_santiago3 from './cammino_santiago_3.jpg'
import cammino_santiago4 from './cammino_santiago_4.jpg'
import piano_annuale_cammino from './piano_annuale_cammino.jpg'

class Slowtourism extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Slowtourism
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              <label className="font-weight-bold">Santiago - Cammino del Nord</label>
              <br />Per affrontare un cammino del genere bisogna essere in buona forma fisica o, perlomeno, non si deve soffrire di fastidi osteoarticolari in zone come schiena, ginocchia e caviglie, articolazioni più sollecitate.
          <br />Meglio sarebbe affrontare,  prima della partenza, una pianificazione annuale di sei mesi.
          <br />Nella sub fase di preparazione generale ci sarà un periodo di adattamento anatomico in palestra di almeno 6/8 settimane per abituare articolazioni, tendini e legamenti ai continui carichi che bisogna affrontare giornalmente dato che, mediamente, si affrontano dai 25 ai 35 km al giorno con ascese giornaliere che possono anche arrivare a 500/700 metri con uno zaino sulle spalle nell’ordine dei 10/12 kg. L’allenamento in palestra darebbe l’opportunità al corpo di sopportare il carico fisico giornaliero, facendolo abituare alla fatica.
          <br />In preparazione specifica si potrà affrontare un periodo di “forza massima funzionale”di 6/9 settimane per poterla poi convertire in resistenza muscolare di lunga durata: la qualità della forza che servirà per affrontare il viaggio. Si lavorerà sulla resistenza aerobica per abituare il corpo al lavoro specifico: questo periodo potrà durare altre 8 settimane.
          <br />Molta attenzione sarà dedicata al “core” (addominali/lombari/obliqui/flessori dell’anca/dorsali/glutei) che permetteranno di distribuire la forza agli arti e di non sovraccaricare troppo la schiena.
          <br />Prima di iniziare il piano annuale si farà un test plicometrico e una bia per valutare il quadro metabolico del soggetto e quindi intervenire anche sulla percentuale del grasso corporeo, anche con il supporto di un professionista dell’alimentazione, in caso di bisogno. Inoltre verrà effettuato un esame posturale per poter inserire i giusti esercizi.
          <br />Di seguito alcune foto del mio cammino percorso nel 2014, un riassunto del modello prestativo e un esempio di una pianificazione semestrale con l’obiettivo di preparare una persona ad affrontare una sfida in sicurezza, come il cammino di Santiago, che porta a percorrere 900/1000 km circa in 30/35 giorni.
          <br />E’ stata un’esperienza indimenticabile che mi ha anche permesso di capire quali sono i fattori fisici limitanti. Quando tornai dal cammino di Santiago mi domandai se poteva esserci un percorso più corto per dare la possibilità a tutti di poter apprezzare i benefici fisici ma soprattutto mentali che camminare comporta.
          <br />Da li mi venne l’idea di percorrere il giro del lago Maggiore con dei miei clienti. Lo completai nel 2015 in senso orario e nel 2016 in senso antiorario. Mi sono reso conto che, con le dovute precauzioni, e’ fattibile per tutte le persone in buona salute.
          <br />Noi lo finimmo in 6 giorni - tutte e due le volte - con una media di 34 km al giorno.
          <br />Nella sezione “Cosa dicono di me” alcune foto da noi scattate sia del 2015 che del 2016 e un’intervista che mi fece il quotidiano “La Provincia” .
        </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <img src={piano_annuale_cammino} className="img-fluid" alt="" />
          </MDBCol>
          <p className="text-justify  white-text">
            <br />MODELLO PRESTATIVO:
          <br />•	Sistema ergogenesi dominante:aerobico
          <br />•	Fattori limitanti la prestazione:resistenza muscolare di lunga durata
          <br />•	Obiettivi dell’allenamento:sviluppare un buon carico fisico,aumentare la resistenza muscolare di lunga durata sopprattuto sulle gambe e rinforzare il core abituandolo a sopportare le 7/8 ore di cammino al giorno con zaino sulle spalle,in ultimo raggiungere una percentuale di massa grassa che consenta di affrontare il cammino nel miglior modo possibile.
          </p>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12">
            <img src={cammino_santiago1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino_santiago2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino_santiago3} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino_santiago4} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              <label className="font-weight-bold">Lago Maggiore</label>
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino3} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={cammino4} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />

      </MDBContainer>
      <br />
    </div >
  }
}

export default Slowtourism