import React from 'react';
import '../Whole.scss'
import '../experiences/Experiences.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import tesi_handbike from './tesi_handbike.pdf'
import tesi_maratona from './tesi_maratona.pdf'
import img_handbike from './img_handbike.jpg'
import img_maratona from './img_maratona.jpg'
import ReactGA from 'react-ga'

class Publications extends React.Component {

  constructor() {
    super()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              DIVULGAZIONE
        </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid" src={img_handbike} alt="" />
              <div className="cer-body">
                <h4 className="cer-title">Handbike</h4>
                <p className="cer-description">
                  Autore: Stefano Benini
              </p>
                <a href={tesi_handbike} download rel="noopener noreferrer">
                  download
                </a>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid" src={img_maratona} alt="" />
              <div className="cer-body">
                <h4 className="cer-title">La programmazione della forza in un maratoneta non professionista master</h4>
                <p className="cer-description">
                  Autore: Stefano Benini
              </p>
                <a href={tesi_maratona} download rel="noopener noreferrer">
                  download
                </a>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }

}

export default Publications