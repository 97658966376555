import React from 'react';
import { Route, HashRouter } from 'react-router-dom'
import '../Whole.scss';
import './Services.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import pianificazione1 from './pianificazione1.jpg'
import pilates from '../pilates/all1.jpg'
import analisi from '../analisi/biaacc_ppg1.jpg'
import personal from './personal1.jpg'
import ReactGA from 'react-ga'

class Services extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickPianificazione = this.handleClickPianificazione.bind(this)
    this.handleClickPersonal = this.handleClickPersonal.bind(this)
    this.handleClickPilates = this.handleClickPilates.bind(this)
    this.handleClickAnalisi = this.handleClickAnalisi.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleClickPianificazione(e, history) {
    history.push('/services/pianificazione')
  }

  handleClickPersonal(e, history) {
    history.push('/services/personal')
  }

  handleClickPilates(e, history) {
    history.push('/services/pilates')
  }

  handleClickAnalisi(e, history) {
    history.push('/services/analisi')
  }

  render() {
    return <div className="background">
      <Route render={({ history }) => (
        <div>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12">
                <label className="title text-center white-text pt-4">
                  SERVIZI
      </label>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol sm="12">
                <p className="text-justify  white-text">
                  In questa pagina trovate i servizi da me offerti.
                <br /><br />In qualità di personal trainer ho il compito di impostare un programma di allenamento in base all’obiettivo di ognuno di voi… Che si tratti di una disciplina sportiva per affrontare una competizione o per il solo piacere di farla, o per la partecipazione ad un programma di fitness per l’aumento della massa magra, la perdita della massa grassa o per il miglioramento posturale anche con l’ausilio del pilates.
                <br /><br />Nella sezione analisi - in breve - vi descrivo i metodi e i dispositivi che utilizzo per acquisire le informazioni necessarie derivanti dal sistema nervoso autonomo e dalla sfera antropometrica, metabolica e posturale, per stilare un programma di allenamento personalizzato e individualizzato al vostro obiettivo.
                </p>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>

              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={pianificazione1} alt=""
                    onClick={e => this.handleClickPianificazione(e, history)} />
                  <br />
                  <h4 className="text-center white-text">Pianificazione Sport</h4>
                </div>
              </MDBCol>

              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={personal} alt=""
                    onClick={e => this.handleClickPersonal(e, history)} />
                  <br />
                  <h4 className="text-center white-text">Personal Training</h4>
                </div>
              </MDBCol>

              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={pilates} alt=""
                    onClick={e => this.handleClickPilates(e, history)} />
                  <br />
                  <h4 className="text-center white-text">Pilates</h4>
                </div>
              </MDBCol>

              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={analisi} alt=""
                    onClick={e => this.handleClickAnalisi(e, history)} />
                  <br />
                  <h4 className="text-center white-text">Analisi</h4>
                </div>
              </MDBCol>
            </MDBRow>
            <br />
          </MDBContainer>
        </div >
      )
      } />
    </div >
  }
}

export default Services