import React from 'react';
import { Route, HashRouter } from 'react-router-dom'
import '../Whole.scss';
import '../services/Services.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import plicometria from './plicometria1.jpg'
import biaacc from './biaacc1.jpg'
import ppgstressflow from './ppgstressflow1.jpg'

class Analisi extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickBiaacc = this.handleClickBiaacc.bind(this)
    this.handleClickPpg = this.handleClickPpg.bind(this)
    this.handleClickPlicometria = this.handleClickPlicometria.bind(this)
  }

  handleClickBiaacc(e, history) {
    history.push('/services/analisi/biaacc')
  }

  handleClickPpg(e, history) {
    history.push('/services/analisi/ppg')
  }

  handleClickPlicometria(e, history) {
    history.push('/services/analisi/plicometria')
  }

  render() {
    return <div className="background">
      <Route render={({ history }) => (
        <div>
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12">
                <label className="title text-center white-text pt-4">
                  Analisi
      </label>
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBCol sm="12">
                <p className="text-justify white-text">
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={biaacc} alt=""
                    onClick={e => this.handleClickBiaacc(e, history)} />
                  <br />
                  <h4 className="text-center white-text">BIA ACC BIOTEKNA</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={ppgstressflow} alt=""
                    onClick={e => this.handleClickPpg(e, history)} />
                  <br />
                  <h4 className="text-center white-text">PPG BIOTEKNA</h4>
                </div>
              </MDBCol>
              <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
                <div className="service-tile mouse">
                  <img className="img-fluid rounded-circle" src={plicometria} alt=""
                    onClick={e => this.handleClickPlicometria(e, history)} />
                  <br />
                  <h4 className="text-center white-text">Plicometria</h4>
                </div>
              </MDBCol>
            </MDBRow>
            <br />
          </MDBContainer>
        </div>
      )} />
    </div>
  }
}

export default Analisi