import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import cer_fipe2 from './certs/FIPE_2_LIVELLO1.jpg'
import cer_fipe3 from './certs/FIPE_3_LIVELLO1.jpg'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'

class Fipe extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              FEDERAZIONE ITALIANA PESISTICA
        </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              FIPE Strength Academy è la scuola Nazionale di Formazione,
              Certificazione, Alta Specializzazione e Aggiornamento della Federazione Italiana Pesistica.
        <br />
              <br />
        Il piano formativo Strength Academy si basa sull’esperienza pluriennale della Federazione Italiana Pesistica ed è sviluppato in totale aderenza allo SNaQ (Sistema Nazionale di Qualifiche dei Tecnici Sportivi) promosso dal CONI tramite la Scuola dello Sport, in stretta connessione con il Quadro di Riferimento Europeo.
        </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="http://www.federpesistica.it/" target="_blank" rel="noopener noreferrer">
              http://www.federpesistica.it/
          </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_fipe2} alt=""
                onClick={(e => this.handleClick(e, cer_fipe2))} />
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_fipe3} alt=""
                onClick={(e => this.handleClick(e, cer_fipe3))} />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Fipe)