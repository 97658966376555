import React from 'react';
import '../Whole.scss'
import './Contact.scss'
import contact from './contact.png'
import ReactGA from 'react-ga'

class Contacts extends React.Component {
  constructor() {
    super()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return <div className="background
    d-flex flex-column justify-content-around">
      <div className=" text-center m-4" >
        <img src={contact} className="img-fluid contact-img" alt="" />
      </div>
      <div className=" text-center m-4">
        <div className="text-justify contact-box">
          <h4 className="color-quote">facebook</h4>
          <a className="text-default"
            href="https://it-it.facebook.com/pages/category/Fitness-Trainer/Stefano-Benini-Personal-Trainer-385495228324522/" target="_blank" rel="noopener noreferrer">
            https://it-it.facebook.com/pages/category/Fitness-Trainer/Stefano-Benini-Personal-Trainer-385495228324522/
                </a>
        </div>
      </div>

      <div className="text-center m-4">
        <div className="text-justify contact-box">
          <h4 className="color-quote">e-mail</h4>
          <a className="text-default"
            href="mailto: abc@example.com" rel="noopener noreferrer">
            stefano74.benini@libero.it
              </a>
        </div>
      </div>

    </div>
  }

}

export default Contacts