import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import personal2 from './personal2.jpg'

class Personal extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Personal Training
            </label>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={personal2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              In questa sezione si vuole descrivere, nello specifico, come si evolve il percorso di personal training sia a distanza, sia effettuando degli allenamenti individuali nel corso della settimana.
              <br /><br />
              <label className="font-weight-bold">A DISTANZA</label>: con il servizio di personal training a distanza il cliente potrà allenarsi in completa autonomia - nella propria palestra e con una programmazione annuale ad hoc da me creata -  solo dopo aver effettuato un’analisi dettagliata: BIA - PLICOMETRIA - ESAME POSTURALE - TEST FISICI SPECIFICI. E’ previsto, in caso di bisogno, un supporto con e-mail,  telefono o skype. Ad ogni cambio scheda, in mia presenza, ci saranno ulteriori test e spiegazione del nuovo programma di allenamento.
              <br />I cambi scheda verranno effettuati normalmente ogni 6 / 8 settimane, i test mi permetteranno di capire se si stà percorrendo la strada giusta, dandomi la possibilità di intervenire in caso ci sia qualche problema.
              <br />Si potrà utilizzare questo servizio per un minimo di 6 mesi, periodo poi rinnovabile se si vorrà proseguire. Verranno anche dati dei consigli alimentari.
              <br />La programmazione annuale potrà essere pianificata per qualsiasi sport individuale o di squadra, così come per il semplice fitness, con l’intento di aumentare la massa magra, diminuire la massa grassa o migliorare l’aspetto posturale.
              <br /><br />
              <label className="font-weight-bold">INDIVIDUALE</label>: con il servizio di personal training individuale ci si potrà allenare con un rapporto 1:1, in mia presenza. Le prime 2 / 3 sedute saranno dedicate ai seguenti test: BIA – PLICOMETRIA- ESAME POSTURALE – TEST FISICI SPECIFICI. Poi, in base all’obiettivo concordato, si effettueranno insieme le sedute di allenamento. Ogni 3 / 6 settimane si effettueranno dei re-test per assicurarsi di essere sulla strada corretta o verranno effettuate delle modifiche sulla programmazione.
              <br />Verrà, anche in questo caso, stilata una pianificazione a medio e lungo termine, il tutto con dei consigli sotto la sfera dell’educazione alimentare.
              <br />E’ possibile usufruire di questo servizio sia con sedute singole - in caso si vogliano solo avere spiegazioni riguardanti esecuzione di esercizi o una semplice consulenza – o di effettuare le analisi sopra elencate con dei pacchetti a breve e medio termine.
            </p>
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Personal

