import React from 'react'
import { Route, HashRouter } from 'react-router-dom'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import str1 from './stru1.jpg'
import str2 from './stru2.jpg'

class Pilates extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickReformer = this.handleClickReformer.bind(this)
    this.handleClickLadder = this.handleClickLadder.bind(this)
  }

  handleClickReformer(e, history) {
    history.push('/services/pilates/reformer')
  }

  handleClickLadder(e, history) {
    history.push('/services/pilates/ladder')
  }


  render() {
    return <div className="background">
      <Route render={({ history }) => (

        <MDBContainer>
          <MDBRow>
            <MDBCol sm="12">
              <label className="title text-center white-text pt-4">
                Pilates
            </label>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="12">
              <p className="text-justify  white-text">
                Il pilates è un tipo di ginnastica (metodo fitness) di tipo rieducativo, preventivo ed ipoteticamente
                terapeutico (riabilitativo), focalizzato sul controllo della postura – tramite la "regolazione del
                baricentro" – a sua volta necessario a guadagnare maggiore armonia e fluidità nei movimenti.
<br />A differenza di molti tipi di ginnastica, il metodo pilates segue rigorosamente principi fondati su
una precisa base filosofica e teorica. Con ordine, il meccanismo di funzionamento del metodo
pilates potrebbe essere sintetizzato come segue:
</p>

              <p className="white-text">
                1.	Individuazione del "problema" o dello scompenso, analizzando il baricentro del corpo.
<br />2.	Acquisizione della tecnica di pilates, rispettando i principi di concentrazione, controllo, fluidità, precisione e respirazione.
<br />3.	Pratica regolare e sistematica degli esercizi di pilates, con regolare verifica del baricentro e della postura.
</p>

              <p className="white-text">
                <br />Benefici del pilates:
              <br />• flessibilità ed estensione del movimento;
              <br />• coordinazione;
              <br />• forza e resistenza muscolare;
              <br />• postura statica e dinamica;
              <br />• controllo del centro del corpo (core), del quale fa parte anche il pavimento pelvico;
              <br />• qualità della vita;
              <br />• capacità di adattamento e percezione;
              <br />• autostima e responsabilità verso il proprio corpo;
              <br />• controllo e la concentrazione mentale;
              <br />• respirazione che diventa più efficiente;
              <br />• connessione mente-corpo.
            </p>

            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
              <div className="service-tile mouse">
                <img className="img-fluid rounded-circle" src={str1} alt=""
                  onClick={e => this.handleClickReformer(e, history)} />
                <br />
                <h4 className="text-center white-text">REFORMER</h4>
              </div>
            </MDBCol>
            <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center mt-4 mb-4">
              <div className="service-tile mouse">
                <img className="img-fluid rounded-circle" src={str2} alt=""
                  onClick={e => this.handleClickLadder(e, history)} />
                <br />
                <h4 className="text-center white-text">LADDER BARREL</h4>
              </div>
            </MDBCol>
          </MDBRow>
          <br />


        </MDBContainer>
      )} />
    </div >
  }
}

export default Pilates