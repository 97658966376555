import React from 'react';
import '../Whole.scss'
import './Theysay.scss'
import pt3 from './pt3.jpg'
import pt4 from './pt4.jpg'
import pt5 from './pt5.jpg'
import pt6 from './pt6.jpg'
import pt7 from './pt7.jpg'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { connect } from 'react-redux'
import { toggleModal, changeModalImage } from '../store/actions'
import mio_allenatore_preferito1 from './mio_allenatore_preferito1.jpg'
import mio_allenatore_preferito2 from './mio_allenatore_preferito2.jpg'
import ReactGA from 'react-ga'
import mich_and1 from './mich_and1.jpg'
import mich_and2 from './mich_and2.jpg'
import mich_and3 from './mich_and3.jpg'
import alessia from './alessia.jpg'

class Theysay extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              DICONO DI ME
            </label>
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12" lg="6"
            className="d-flex justify-content-center align-items-center-center p-4">
            <img className="article img-fluid" src={pt5} alt=""
              onClick={(e => this.handleClick(e, pt5))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="article img-fluid" src={pt4} alt=""
              onClick={(e => this.handleClick(e, pt4))} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="article img-fluid" src={pt3} alt=""
              onClick={(e => this.handleClick(e, pt3))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="article img-fluid" src={pt7} alt=""
              onClick={(e => this.handleClick(e, pt7))} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6" className="d-flex justify-content-center align-items-center-center p-4">
            <img className="article img-fluid" src={pt6} alt=""
              onClick={(e => this.handleClick(e, pt6))} />
          </MDBCol>
        </MDBRow>
        <br />
        <br />
      </MDBContainer>
      <hr className="border border-dark mx-5" />
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify font-italic white-text pt-4">
              Sono Anna Marina Carriero, atleta della a.s.d. Polha Varese dall'anno sportivo 2017/2018 nell'ambito dell'handbike, praticato a livello agonistico, e del sitting volley, al momento solo promozionale.
              <br />Stefano Benini è il mio preparatore da settembre 2017, ma chiamarlo preparatore forse è riduttivo, perchè nel tempo il rapporto si è sviluppato ed è stato ed è molto di più. Oltre a prepararmi le schede di allenamento e a correggere l'esecuzione degli esercizi, non lesina consigli sull'alimentazione e su altri aspetti della salute. Inoltre l'anno scorso, essendo stata sottoposta ad un intervento alla spalla per la rottura del sovraspinato, mi ha accompagnato ad una delle visite dell'ortopedico e ha incontrato il mio fisioterapista per programmare al meglio gli esercizi per il recupero funzionale: non credo sia da tutti gli allenatori una tale cura!  Finita la fisioterapia in clinica, abbiamo lavorato sodo e in poco tempo il recupero di mobilità e forza della spalla e del braccio è stato completato, con i complimenti dell'ortopedico.
              <br />Dopo una prima stagione agonistica molto soddisfacente (anno 2017/2018) con podi a tutte le gare tranne una, tra cui un terzo posto a Monza, gara di esordio assoluto per me, un bronzo anche ai Campionati Italiani, un argento alla gara di Pescantina e di Pavia, un primo posto ad Albizzate e al Trofeo Lampre e in chiusura un terzo posto alla gara di casa, l'anno successivo purtroppo non ho potuto gareggiare a causa della spalla. Quest'anno che mi sentivo alla grande grazie all'allenamento intensivo la stagione è stata bloccata sul nascere dall'emergenza Covid...
              <br />Pazienza, non si molla niente, anzi ci si prepara con ancora più impegno per l'anno prossimo, sperando nello svolgimento regolare di almeno un paio di gare ad ottobre che mi serviranno per riacquistare confidenza con la strada e con la competizione.
              <br />Ora Stefano dovrà anche insegnarmi come tenere sotto controllo l'ansia da gara che ancora turba le mie notti pre-gara :D

            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol size="12" lg="8" className="text-center">
            <img src={mio_allenatore_preferito1} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
          <MDBCol size="12" lg="4" className="text-center">
            <img src={mio_allenatore_preferito2} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
        </MDBRow>

        <br />

      </MDBContainer>

      <hr className="border border-dark mx-5" />
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify font-italic white-text pt-4">
              Parliamo dello Ste!
              <br />Iniziare questo mio breve discorso parlando esclusivamente delle sue doti lavorative non mi va …
              <br />Quello di cui vi voglio parlare è del suo lato umano... Come è Stefano dal punto di vista umano? Che persona è?
              <br />Se dovessi descrivere tutto, con una parola sola, direi sicuramente "VERO".
              <br />Stefano è una persona vera, senza maschere, una persona umile e dal cuore davvero grande: doti perennemente evidenziate dalla sua costante voglia di mettersi in gioco, dal continuo cercare la soluzione migliore al problema, dal modo in cui si prende a cura le problematiche per offrirti sempre la soluzione migliore e, vi garantisco, soluzione sempre vincente!
              <br />La nostra reale collaborazione è iniziata nel 2011 quando ho deciso di intraprendere una carriera lavorativa dove, se si vuole fare la differenza e superare molti step selettivi,la preparazione fisica e mentale giocano un ruolo fondamentale!
              <br />Fino ad allora non avevo mai sentito (molto erroneamente) la necessità di farmi seguire da un preparatore, accontentandomi dei risultati mediocri da autodidatta, ottenuti senza le dovute conoscenze.
              <br />L'aiuto di Stefano si è rivelato fin da subito fondamentale ... Aiuto senza il quale, forse, ora non avrei raggiunto i miei parziali (c'è ancora molto lavoro da fare) obiettivi lavorativi.
              <br />Sappiamo tutti che per chi fa molto sport, o fa lavori gravosi con il proprio corpo, gli infortuni sono sempre dietro l'angolo….e infatti...rottura del cercine della spalla sinistra!
              <br />Ed è qua che Stefano ha dimostrato di che pasta è fatto!
              <br />Dopo l'operazione e il mese con il tutore a braccio immobile ho iniziato un percorso di riabilitazione che, in poco tempo, mi ha riportato nel pieno delle mie forze. Con un intenso lavoro e le sue innegabili doti e conoscenze siamo riusciti, quasi in metà tempo, a riportarmi in pista, lasciando stupito anche il chirurgo che mi ha operato!
              <br />Per concludere ragazzi:
              <br />Se quello che cercate è un professionista che, spronandovi, vi porti a raggiungere i vostri obbiettivi ... se cercate non solo un preparatore ma anche una figura di riferimento, sempre pronta ad aiutarvi, beh….non esitate ... Stefano è la persona che fa al caso vostro!
              <br /><br />Matteo, soldato dell'Esercito Italiano
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br />
      <hr className="border border-dark mx-5" />
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify font-italic white-text pt-4">
              Siamo Michela (34 anni) e Andrea (31 anni) sportivi a livello amatoriale con alle spalle qualche anno di allenamento in palestra.
              <br /> Essendo arrivati ad un punto di stallo a livello sia di risultati che motivazionale, abbiamo deciso di affidarci a Stefano circa un anno fa, il quale ha dimostrato fin da subito grande professionalità e affidabilità, oltre alle grandi competenze motivanti e incoraggianti, seguendoci passo per passo e individuando in ognuno di noi punti deboli e potenzialità.
              <br /> Dopo pochi mesi seguendo il suo programma di allenamento e, con qualche consiglio alimentare, abbiamo notato subito risultati eccezionali .
              <br /> Anche grazie all’aiuto di una buona respirazione diaframmatica guidata dall’analisi della BIA ci ha aiutato ad ottenere un miglior benessere psico-fisico.

              <br /><br /> Per quanti riguarda me (Michela) mi sono rivolta a Stefano sopratutto per un problema di infiammazione alle gambe (cellulite).
              <br /> Dopo qualche settimana dall’inizio del programma già non accusavo più pesantezza e visivamente le gambe apparivano più toniche, il tutto sfatando il mito che più le si allena e meglio e’!!!
              <br /> Le sedute di allenamento sono state adeguate alle mie esigenze e mi hanno aiutato a liberarmi in modo quasi definito della ritenzione idrica, a differenza degli allenamenti che ho provato precedentemente che sembravano peggiorare la mia situazione.
              <br /> Morale... Ho mangiato di più, mi sono allenata di meno e ho raggiunto un equilibrio psicofisico ottimo!
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="align-items-center">

        <MDBRow>
          <MDBCol size="12" md="2" lg="3" className="text-center">
          </MDBCol>
          <MDBCol size="12" md="8" lg="6" className="text-center">
            <img src={mich_and1} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
          <MDBCol size="12" md="2" lg="3" className="text-center">
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol>
            <p className="text-justify font-italic white-text pt-4">
              <br /> Per quanto riguarda me (Andrea), dopo anni di allenamenti in palestra con metodologie base, si delineava fin da subito una differenza particolare tra lo stato di forma fisica e i livelli di forza, ovvero seppur in una buona massa muscolare, essa risultava abbastanza bassa.
              <br /> Inoltre nell’ultimo anno, ho avuto un calo motivazionale con conseguente riduzione della qualità dell’allenamento e del risultato estetico.
              <br /> Con i consigli e il programma di Stefano sono riuscito a trovare prima di tutto la spinta e la voglia di un tempo per affrontare al top ogni giorno di allenamento e organizzare tutto quello che riguarda uno stile di vita sano.
              <br /> La ricomposizione corporea che ho ottenuto è stata eccezionale ottenendo le basi per una crescita muscolare veramente ottimale!
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol size="12" md="6" className="text-center">
            <img src={mich_and2} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
          <MDBCol size="12" md="6" className="text-center">
            <img src={mich_and3} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol>
            <p className="text-justify font-italic white-text pt-4">
              Ed infine quindi ringraziamo Stefano per essere professionale e paziente; ed allo stesso tempo in grado di dare la giusta carica per affrontare il percorso che, all’inizio, e’ sicuramente impegnativo ma i risultati ripagano!!!
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br />
      <hr className="border border-dark mx-5" />
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol>
            <p className="text-justify font-italic white-text pt-4">
              Ho conosciuto Stefano grazie al proprietario del poliambulatorio dove lavora come personal trainer da più di 10 anni.
              <br />Tempo fa il proprietario del Poliambulatorio aveva una palestra che io ho frequentato, dove mi sono sempre trovata bene.
              <br />Dopo la seconda gravidanza mi feci seguire da un dietologo e poi da un personal trainer in una nuova palestra. Persi chili ma la mia plicometria era pessima.  Ero ferma da 1 anno con il peso nonostante un allenamento di 4 volte alla settimana, seguendo un’alimentazione consigliatami. Stufa della situazione di stallo, tornai in questo Poliambulatorio, non vicino a casa mia (che non frequentavo da anni), dove mi venne consigliato Stefano. Stefano mi ha aiutato con dei consigli alimentari e mi ha insegnato ad allenarmi a casa e da sola, considerando anche l’emergenza Covid. In pochi mesi, da luglio 2020 a marzo 2021 si sono visti dei buoni risultati e la plicometria è decisamente migliorata... Adesso seguo costantemente i consigli alimentari e i piani di allenamento di Stefano con positivi feedback periodi.  Con 2 bambini piccoli (di 3 e 6 anni) è dura ma non impossibile. Ci vuole costanza, determinazione e un OTTIMO personal trainer.
              <br />Grazie Stefano!
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className="align-items-center">
        <MDBRow>
          <MDBCol size="12" md="2" lg="3" className="text-center">
          </MDBCol>
          <MDBCol size="12" md="8" lg="6" className="text-center">
            <img src={alessia} className="img-fluid mt-4 mb-4" alt="" />
          </MDBCol>
          <MDBCol size="12" md="2" lg="3" className="text-center">
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <br />
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Theysay)