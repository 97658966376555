import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import cer_isci_rrts from './certs/ISCI_RTS1.jpg'
import cer_isci_scs from './certs/ISCI_SCS1.jpg'
import cer_isci_ssc from './certs/ISCI_SSC1.jpg'
import cer_isci_nutr from './certs/ISCI_SNSE1.jpg'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'

class Isci extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              INTERNATIONAL STRENGTH&CONDITIONING INSTITUTE
        </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              L’International Strength & Conditioning Institute è un’associazione internazionale che promuove una metodologia dell’allenamento sensata e basata sull’evidenze così da fare da ponte tra la scienza e la pratica dell’allenamento sportivo.
        <br />
              <br />
        I corsi di certificazione dell’ISCI permettono a personal trainers e preparatori atletici di lavorare efficacemente con atleti di qualsiasi sport.
      </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="https://www.isci.education/" target="_blank" rel="noopener noreferrer">
              https://www.isci.education/
          </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid mouse" src={cer_isci_rrts} alt=""
                onClick={(e => this.handleClick(e, cer_isci_rrts))} />
              <div className="cer-body">
                <h4 className="cer-title">Specialista in Tecniche di Recupero & Rigenerazione</h4>
                <p className="cer-description">
                  La certificazione di Specialista in Tecniche di Recupero & Rigenerazione (Recovery & Regeneration Techniques Specialist – RTS™)
                  nasce dalla consapevolezza della mancanza di conoscenze specifiche degli operatori nel campo del fitness come della preparazione atletica,
                  a riguardo delle tecniche rigenerative, della loro applicazione e della loro pianificazione, nonché delle tecnologie facilmente disponibili per il controllo del recupero.
                  Basandoci su conoscenze teoriche e pratiche pluriennali, nonché su una ricerca applicata sempre in evoluzione, abbiamo deciso di offrire una certificazione del tutto innovativa nell’ambiente nostrano.
                  Nonostante la consapevolezza che il recupero è fondamentale per accelerare e incrementare gli adattamenti all’allenamento, la cura di questa componente essenziale è talvolta completamente ignorata, anche negli sport professionistici.
              </p>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid mouse" src={cer_isci_scs} alt=""
                onClick={(e => this.handleClick(e, cer_isci_scs))} />
              <div className="cer-body">
                <h4 className="cer-title">Specialista in Velocità e Resistenza</h4>
                <p className="cer-description">
                  La certificazione di Specialista in Velocità e Resistenza (Speed & Conditioning Specialist - SCS™) ha come fine la conoscenza della
                  pianificazione e programmazione integrata di tutte le abilità biomotorie (forza, velocità, resistenza), secondo i principi scientifici della teoria e metodologia dell’allenamento,
                  per venire incontro ai bisogni della preparazione atletica di qualsiasi sport. Attraverso questo corso apprenderai concetti quali gli aspetti fondamentali e pratici della fisiologia dell'esercizio,
                  la pianificazione e la programmazione dell'allenamento dei sistemi energetici, la tecnica di corsa nelle varie discipline sportive e come questa influisce su metodi e mezzi d'allenamento,
                  la progressione nel piano annuale dell'allenamento di velocità e resistenza a seconda dello sport, e l’interrelazione degli aspetti tecnico, tattico, e psicologico, con l'aspetto fisico.
                </p>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid mouse" src={cer_isci_ssc} alt=""
                onClick={(e => this.handleClick(e, cer_isci_ssc))} />
              <div className="cer-body">
                <h4 className="cer-title">Allenatore della Forza per lo Sport</h4>
                <p className="cer-description">
                  La certificazione di Allenatore della Forza per lo Sport (Sport Strength Coach -SSC™) intende sviluppare la capacità di applicare in modo metodico i principi scientifici alla pratica
                  dell’allenamento della forza in ambito sportivo. La manipolazione delle variabili di carico a seconda delle fasi del piano annuale, al fine di ottenere gli adattamenti prefissati e il
                  miglioramento della prestazione atletica, viene esposta e discussa con un costante riferimento, sia alla scienza, sia alla pratica sul campo in diversi sport e a diversi livelli di competizione.
                  Concetti metodologici come l’analisi del modello prestativo, la redazione del piano annuale, le variabili della pianificazione a medio e lungo termine, le caratteristiche dell’allenamento nelle
                  fasi preparatoria, competitiva e transitoria, la programmazione del microciclo e del macrociclo, e le tecniche per il raggiungimento del picco di forma, sono esposti in maniera da permetterti
                  una scelta consapevole e sensata delle variabili dell’allenamento e della loro progressione per il tuo sport. Le conoscenze che acquisirai sicuramente cambieranno la tua prospettiva sulla
                  pianificazione e sulla programmazione della forza per lo sport.
              </p>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert">
              <img className="img-fluid mouse" src={cer_isci_nutr} alt=""
                onClick={(e => this.handleClick(e, cer_isci_nutr))} />
              <div className="cer-body">
                <h4 className="cer-title">Esperto in Nutrizione ed  Integrazione Alimentare Applicate allo Sport</h4>
                <p className="cer-description">
                  La certificazione di Esperto in Nutrizione ed  Integrazione Alimentare Applicate allo Sport  (Sport
                  Nutrition &amp; Supplementation Expert – SNSE™) fornisce le conoscenze di base per la comprensione
                  della nutrizione sportiva e intende informare in maniera seria e approfondita a riguardo dell’uso degli
                  integratori alimentari in ambito sportivo, facendo continuo riferimento alle ultime ricerche in questo campo.
              </p>
              </div>
            </div>
          </MDBCol>


          {/* <MDBCol sm="12" lg="6" className="cont-cert">
            <img src={cer_isci_rrts} alt="" className="cert" alt=""
              onClick={(e => this.handleClick(e, cer_isci_rrts))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="cont-cert">
            <img src={cer_isci_scs} alt="" className="cert" alt=""
              onClick={(e => this.handleClick(e, cer_isci_scs))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="cont-cert">
            <img src={cer_isci_ssc} alt="" className="cert" alt=""
              onClick={(e => this.handleClick(e, cer_isci_ssc))} />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="cont-cert">
            <img src={cer_isci_nutr} alt="" className="cert" alt=""
              onClick={(e => this.handleClick(e, cer_isci_nutr))} />
          </MDBCol> */}

        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Isci)