import React from 'react';
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import {
  MDBContainer, MDBBtn, MDBModal,
  MDBModalBody, MDBModalFooter, MDBIcon
} from 'mdbreact'
import './Topbar.scss'
import { hideSubmenuCert, toggleSubmenuCert } from '../store/actions'

class TopbarMenuCert extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClickToggle = this.handleClickToggle.bind(this)
    this.refSubmenu = React.createRef()
    this.handleClickFipe = this.handleClickFipe.bind(this)
    this.handleClickIsci = this.handleClickIsci.bind(this)
    this.handleClickIssa = this.handleClickIssa.bind(this)
    this.handleClickNcs = this.handleClickNcs.bind(this)
    this.handleClickNsca = this.handleClickNsca.bind(this)
    this.handleClickNsca1 = this.handleClickNsca1.bind(this)
    this.handleClickNsca2 = this.handleClickNsca2.bind(this)
    this.handleClickOpen = this.handleClickOpen.bind(this)

  }

  handleClickOutside(event) {
    if (this.refSubmenu.current && !this.refSubmenu.current.contains(event.target)) {
      this.props.hideSubmenuCert()
    }
  }

  handleClickToggle(event) {
    this.props.toggleSubmenuCert()
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickFipe(e, history) {
    history.push('/experiences/fipe')
    this.props.hideSubmenuCert()
  }

  handleClickIsci(e, history) {
    history.push('/experiences/isci')
    this.props.hideSubmenuCert()
  }

  handleClickIssa(e, history) {
    history.push('/experiences/issa')
    this.props.hideSubmenuCert()
  }

  handleClickNcs(e, history) {
    history.push('/experiences/ncs')
    this.props.hideSubmenuCert()
  }

  handleClickNsca(e, history) {
    history.push('/experiences/nsca')
    this.props.hideSubmenuCert()
  }

  handleClickNsca1(e, history) {
    history.push('/experiences/nsca1')
    this.props.hideSubmenuCert()
  }

  handleClickNsca2(e, history) {
    history.push('/experiences/nsca2')
    this.props.hideSubmenuCert()
  }

  handleClickOpen(e, history) {
    history.push('/experiences/open')
    this.props.hideSubmenuCert()
  }

  render() {
    return (
      <Route render={({ history }) => (
        <div ref={this.refSubmenu}>
          <div className="submenu">
            <div className="submenu-arrow">
              <MDBIcon icon="angle-down" className="mouse" onClick={(e => this.handleClickToggle(e))} />
            </div>
            {
              this.props.submenuCertOpen
                ?
                <div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickIsci(e, history))}> ISCI </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickIssa(e, history))}> ISSA </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickFipe(e, history))}> FIPE </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickNsca1(e, history))}> NSCA CPT </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickNsca2(e, history))}> NSCA CSCS </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickNcs(e, history))}> NCS </label>
                  </div>
                  <div className="submenu-item">
                    <label onClick={(e => this.handleClickOpen(e, history))}> Open Academy </label>
                  </div>
                </div>
                :
                null
            }
          </div>

        </div>

      )}
      />
    )

  }
}

const mapStateToProps = state => {
  return {
    submenuCertOpen: state.submenuCertOpen
  }
}

const mapDispatchToProps = {
  hideSubmenuCert,
  toggleSubmenuCert
}


export default connect(mapStateToProps, mapDispatchToProps)(TopbarMenuCert)