import React from 'react';
import './Home.scss';
import logo from './logo.png'
import { Route, HashRouter } from 'react-router-dom'
import '../experiences/Experiences.scss'
import ReactGA from 'react-ga'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickEvent = this.handleClickEvent.bind(this)
    this.handleClickAbout = this.handleClickAbout.bind(this)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleClickEvent(e, history) {
    history.push('/event')
  }

  handleClickAbout(e, history) {
    history.push('/about')
  }

  render() {
    return (
      <Route render={({ history }) => (
        <div className="background
        d-flex flex-column justify-content-around">
          {/* <div className="event">
            &nbsp;
            <MDBBtn tag="a" size="lg" floating gradient="peach"
              onClick={(e => this.handleClickEvent(e, history))}>
              <MDBIcon icon="bolt" className=" mr-1" />
          Evento
        </MDBBtn>
          </div> */}

          <div >
            <h2 className="font-italic text-center color-quote pl-1 pr-1">
              <br />Ascolta, percepisci, visualizza
                  <br />ed agisci con tenacia fino alla conquista dell'obiettivo
                </h2>
          </div>
          <div className="home text-center">
            <img src={logo} className="logo img-fluid" alt="logo"
              onClick={(e => this.handleClickAbout(e, history))}></img>
          </div>
          <div>
            <h2 className="font-italic text-center color-quote pl-1 pr-1">
              Allena la mente ad allenare il fisico
                </h2>
          </div>

        </div>
      )} />
    )
  }
}

export default Home