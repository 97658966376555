import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import piano_annuale_bodybuilding from './piano_annuale_bodybuilding.jpg'

class Bodybuilding extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Bodybuilding
      </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <p className="text-justify white-text">
              <label className="font-weight-bold">Body building</label>, ovvero, “costruzione del corpo”: lo considero più uno stile di vita che uno sport fine a se stesso.
<br />Se lo si interpreta solo come una disciplina per l’aumento della massa muscolare può sembrare riduttiva, ma se si riesce a guardarla con altre sfaccettature si capisce che può essere “la rampa di lancio” per diverse attività.
<br />E’, in primis, uno stile di vita perché per riuscire ad arrivare ad un corpo magro e muscoloso - ovviamente con metodi “natural” - senza aiuti dopanti, bisognerà essere rigorosi sull’ alimentazione, allenamento, riposo e stile di vita adeguato.
<br />Inoltre, l’aumento della massa muscolare, è spesso utile per determinati sport - dove c’è il contrasto – come, ad esempio, football americano, rugby, calcio e basket, solo per citarne alcuni.
<br />In tutti questi sport può succedere che, per ricoprire un determinato ruolo, si ha bisogno di dover aumentare la massa magra.
<br />Inoltre, nell’ultimo decennio, si è capito quanto è importante preservare la massa muscolare che dopo i 40 anni, se non stimolata, avrà una perdita a livello fisiologico, comportando un abbassamento del metabolismo,una riduzione della forza e un peggioramento a livello di densità ossea e posturale.
<br />Si evince l’importanza di questa disciplina nell’insegnamento del corretto stile di vita, nel miglioramento della postura e dello scheletro, nell’aumento della massa magra con l’innalzamento del metabolismo di fondamentale importanza  per il dimagrimento.
<br />In ultimo c’è il body building agonistico ...quello delle gare, l’allenamento con l’obiettivo dell’aumento della massa magra, curando la simmetria, il volume e la densità muscolare con una percentuale di massa grassa bassa per esaltare la figura corporea e prepararla alle competizioni.
<br />Io stesso, nonostante pratico questa disciplina da più di 30 anni, feci la mia prima gara all’età di 33 anni e ancora oggi quando ne ho la voglia gareggio solo per puro divertimento e per migliorare il mio fisico. Le gare mi danno la possibilità di “abbattere paletti” che in condizioni normali non riuscirei a superare.
<br />Ammiro, seguo e consiglio solo persone che si cimentano ad una gara di body building in maniera sana, con fatica, impegno e dedizione, senza l’aiuto di sostanze dopanti illecite.
<br />Devo molto a questa disciplina che mi ha insegnato tanto nella vita.
<br />Di seguito un esempio di pianificazione annuale per una gara di body building a livello nazionale con la suddivisione delle abilità  biomotorie (FORZA-RESISTENZA AEROBICA) con incremento e decremento di volume e intensità fino al raggiungimento del picco il giorno della competizione.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <img src={piano_annuale_bodybuilding} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br />
    </div>
  }
}

export default Bodybuilding