import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import piano_annuale_maratona from './piano_annuale_maratona.jpg'

class Maratona extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Maratona
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              La presente pianificazione annuale ha lo scopo di evidenziare l’importanza della forza in uno sport aerobico di lunga durata come la maratona, prendendo come soggetto un maschio adulto di 47 anni, con un lavoro di responsabilità a tempo pieno. La difficoltà è appunto quella di pianificare una programmazione di circa otto mesi, trovando un connubio tra tempo per gli allenamenti, adattamenti neuromuscolari, innalzamento della performance e, cosa più importante, il miglioramento della qualità di vita del soggetto. (Per ulteriori informazioni sul lavoro svolto consultare la sezione materiale informativo).
<br />Come si può vedere dalla griglia del piano annuale nella riga forza, buona parte dell’allenamento è stata dedicata proprio all’aumento della forza, con una conversione nella qualità dominante, cioè la resistenza muscolare di lunga durata. Il piano annuale, come si può vedere, dà importanza a un lavoro di mantenimento della forza per non decrementare la resistenza muscolare, metodo poco utilizzato dalla media dei runner di lunga distanza.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center" md="12">
            <img src={piano_annuale_maratona} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              MODELLO PRESTATIVO:
<br />sistema energetico dominante: aerobico
<br />substrati energetici dominanti: acidi grassi liberi
<br />fattori limitanti: resistenza muscolare di lunga durata
<br />obiettivi allenamento: capacità aerobica,resistenza muscolare di lunga durata
            </p>
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Maratona