import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import barrel1 from './barrel1.jpg'
import barrel2 from './barrel2.jpg'
import barrel3 from './barrel3.jpg'
import barrel4 from './barrel4.jpg'
import barrel5 from './barrel5.jpg'

class Ladder extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              LADDER BARREL
            </label>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Le barrel sono attrezzi che concorrono in maniera efficace al potenziamento dei muscoli estensori del busto e al rilassamento passivo in posizione di allungamento della colonna vertebrale, estendendo in questo modo i muscoli flessori del busto.
              <br />Durante gli esercizi addominali di flessione in avanti, la barrel mantiene la parte inferiore della schiena in posizione neutra, sostenendola e svolgendo una funzione importante, soprattutto nei casi in cui una flessione della fascia lombare della colonna sia controindicata.
              <br />La barrel svolge, inoltre, una funzione di supporto per il busto durante gli esercizi di flessione laterale, sia di livello base che avanzato, favorendo lo sviluppo concomitante della forza e della flessibilità.
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12" lg="6" className="mt-4 mb-4">
            <img src={barrel1} className="img-fluid" alt="" />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="mt-4 mb-4">
            <img src={barrel2} className="img-fluid" alt="" />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="mt-4 mb-4">
            <img src={barrel3} className="img-fluid" alt="" />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="mt-4 mb-4">
            <img src={barrel4} className="img-fluid" alt="" />
          </MDBCol>
          <MDBCol sm="12" lg="6" className="mt-4 mb-4">
            <img src={barrel5} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Ladder

