import languages from '../menu/languages'
const initialState = {
  menuOpen: false,
  language: languages.en,
  languagePanelOpen: false,
  modalOpen: false,
  modalImage: null,
  lastUpdateOpen: false,
  submenuCertOpen: false,
  submenuServOpen: false
}

const reducer = (state = initialState, action) => {
  console.log('action')
  console.log(action)
  switch (action.type) {
    case 'SHOW':
      return Object.assign({}, state, { menuOpen: true })
    case 'HIDE':
      return Object.assign({}, state, { menuOpen: false })
    case 'TOGGLE':
      return Object.assign({}, state, { menuOpen: !state.menuOpen })
    case 'CHANGE_LANGUAGE_EN':
      return Object.assign({}, state, { language: languages.en })
    case 'CHANGE_LANGUAGE_IT':
      return Object.assign({}, state, { language: languages.it })
    case 'SHOW_LANGUAGE_PANEL':
      return Object.assign({}, state, { languagePanelOpen: true })
    case 'HIDE_LANGUAGE_PANEL':
      return Object.assign({}, state, { languagePanelOpen: false })
    case 'TOGGLE_MODAL':
      return Object.assign({}, state, { modalOpen: !state.modalOpen })
    case 'CHANGE_MODAL_IMAGE':
      return Object.assign({}, state, { modalImage: action.image })
    case 'HIDE_LAST_UPDATE':
      return Object.assign({}, state, { lastUpdateOpen: false })
    case 'TOGGLE_SUBMENU_CERT':
      return Object.assign({}, state, { submenuCertOpen: !state.submenuCertOpen })
    case 'SHOW_SUBMENU_CERT':
      return Object.assign({}, state, { submenuCertOpen: true })
    case 'HIDE_SUBMENU_CERT':
      return Object.assign({}, state, { submenuCertOpen: false })
    case 'TOGGLE_SUBMENU_SERV':
      return Object.assign({}, state, { submenuServOpen: !state.submenuServOpen })
    case 'SHOW_SUBMENU_SERV':
      return Object.assign({}, state, { submenuServOpen: true })
    case 'HIDE_SUBMENU_SERV':
      return Object.assign({}, state, { submenuServOpen: false })
    default:
      return state;
  }
}

export default reducer