import React from 'react';
import { connect } from 'react-redux'
import {
  MDBContainer, MDBBtn, MDBModal,
  MDBModalBody, MDBModalFooter
} from 'mdbreact'
import { toggleModal } from '../store/actions'

class Modal extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    this.props.toggleModal()
  }

  render() {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.props.modalOpen} toggle={this.toggle} size="lg">
          {/* <MDBModalHeader>MDBModal title</MDBModalHeader> */}
          <MDBModalBody>
            <img src={this.props.modalImage} alt="" />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="elegant" onClick={(e => this.handleClick(e))}>Close</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    modalOpen: state.modalOpen,
    modalImage: state.modalImage
  }
}

const mapDispatchToProps = {
  toggleModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)