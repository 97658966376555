import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'
import cer_issa2 from './certs/ISSA_CFT21.jpg'
import cer_issa3 from './certs/ISSA_CFT31.jpg'

class Issa extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              INTERNATIONAL SPORTS SCIENCE ASSOCIATION
        </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              Dal 1997 ISSA Europe è la scuola leader nelle Certificazioni del settore Fitness. Ogni anno migliaia di
              appassionati e professionisti si formano e si aggiornano attraverso i nostri corsi e programmi, garanzia di
              unicità e scientificità.
<br />Sono 10 i percorsi formativi proposti da ISSA Europe, e una Academy - il centro di addestramento per
Personal Fitness Trainer. La Scuola propone formazione pratica, teorica e competenza ad appassionati,
istruttori, personal trainer, laureati, operatori e figure sanitarie, gestori e proprietari di centri fitness.
<br />Lo sviluppo continuo del settore Fitness richiede ai Personal Trainer una preparazione professionale
aggiornata, che ISSA Europe fornisce attraverso i suoi corsi, supporto on line e approfondimento, ma
soprattutto grazie al Programma ECF - Educazione Continua nel Fitness.
<br />ISSA Europe ha sempre promosso e promuove la divulgazione di una corretta ‘in’-formazione relativamente
a Efficienza fisica, Salute e Prevenzione attraverso un corretto stile di vita, alimentazione, attività fisica e
integrazione.
<br />Oltre 25.000 studenti hanno partecipato ai nostri corsi: milgiaia hanno ottenuto e mantenuto le Certificazioni
acquisite, inserendosi come collaboratori di qualità in centinaia di Centri Fitness e Strutture in Italia e nel
resto di Europa. Di recente ISSA Europe è attiva nel modo universitario, organizzando il Premio ISSA
Europe Tesi&amp;Ricerche nell&#39;ambito del Fitness ed Efficienza fisica.
<br />ISSA Europe è promotrice del circuito Fitness di Qualità , il Gruppo di Centri Fitness dove collaborano i
Certificati ISSA Europe.
<br />
              <br />
MISSION
<br />L’International Sports Sciences Association sostiene che stato di salute e forma fisica dei cittadini possono
essere migliorati significativamente grazie all’apporto dei professionisti cui fornisce competenze e nozioni.
<br />L’azione di contrasto all’abnorme declino fisico che nelle nostre società avanzate colpisce tutti in ogni età
può essere rafforzata sfruttando le capacità e la passione dei diplomati ISSA, forti dei principi guida forniti
dall’associazione.
<br />Con oltre 115.000 Personal Trainer certificati in tutto il mondo e migliaia di professionisti impegnati nei
percorsi formativi erogati, ISSA è il leader mondiale nell’erogazione di certificazioni di fitness.
<br />In oltre venti anni di attività lo standard di preparazione richiesta da ISSA ai tecnici del fitness che prepara è
cresciuta esponenzialmente: l’obiettivo è sempre quello di rendere ancora più accessibile la formazione
offerta, garantendo sempre la massima qualità nei corsi di base come nei seminari di approfondimento
tecnico. Da leader nella formazione nel mondo del fitness, ISSA si impegna costantemente per offrire ai suoi
allievi le migliori opportunità di carriera lavorativa, condividendo con loro la volontà di aiutare i cittadini a
vivere in maniera migliore e più salutare.
<br />La missione di ISSA? Motivare i suoi studenti a migliorare la qualità della vita e l&#39;efficienza fisica di ogni
persona con cui entrano in contatto.
        </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="https://www.issa-europe.eu/" target="_blank" rel="noopener noreferrer">
              https://www.issa-europe.eu/
          </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_issa2} alt=""
                onClick={(e => this.handleClick(e, cer_issa2))} />
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_issa3} alt=""
                onClick={(e => this.handleClick(e, cer_issa3))} />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Issa)