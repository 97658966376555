import React from 'react';
import { Route, HashRouter, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import Home from './home/Home'
import About from './about/About'
import Experiences from './experiences/Experiences'
import Event from './event/Event'
import MenuPage from './menu/MenuPage'
import Topbar from './topbar/Topbar'
import Modal from './modal/Modal'
import Fipe from './experiences/Fipe'
import Isci from './experiences/Isci'
import Issa from './experiences/Issa'
import Nsca from './experiences/Nsca'
import Nsca1 from './experiences/Nsca1'
import Nsca2 from './experiences/Nsca2'
import Ncs from './experiences/Ncs'
import Open from './experiences/Open'
import Bodybuilding from './services/Bodybuilding'
import Handbike from './services/Handbike'
import Sci from './services/Sci'
import Nordic from './services/Nordic'
import Maratona from './services/Maratona'
import Slowtourism from './services/Slowtourism'
import Calcio from './services/Calcio'
import LastUpdate from './lastUpdate/LastUpdate'
import ScrollToTop from './ScrollToTop'
import Services from './services/Services'
import Pianificazione from './services/Pianificazione'
import Personal from './services/Personal'
import Contacts from './contacts/contacts'
import Publications from './publications/publications'
import Theysay from './theysay/Theysay'
import Pilates from './pilates/Pilates'
import Analisi from './analisi/Analisi'
import Biaacc from './analisi/Biaacc'
import Ppg from './analisi/Ppg'
import Plicometria from './analisi/Plicometria'
import Ladder from './pilates/Ladder'
import Reformer from './pilates/Reformer'

const App = () => {
  return (
    <div className="App">
      <HashRouter>
        <ScrollToTop />
        {/* <LanguageButton></LanguageButton> */}
        <Modal></Modal>
        <Topbar></Topbar>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/experiences" component={Experiences} />
          <Route exact path="/experiences/fipe" component={Fipe} />
          <Route exact path="/experiences/isci" component={Isci} />
          <Route exact path="/experiences/issa" component={Issa} />
          <Route exact path="/experiences/nsca" component={Nsca} />
          <Route exact path="/experiences/nsca1" component={Nsca1} />
          <Route exact path="/experiences/nsca2" component={Nsca2} />
          <Route exact path="/experiences/ncs" component={Ncs} />
          <Route exact path="/experiences/open" component={Open} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/services/pianificazione" component={Pianificazione} />
          <Route exact path="/services/pianificazione/bodybuilding" component={Bodybuilding} />
          <Route exact path="/services/pianificazione/handbike" component={Handbike} />
          <Route exact path="/services/pianificazione/sci" component={Sci} />
          <Route exact path="/services/pianificazione/nordic" component={Nordic} />
          <Route exact path="/services/pianificazione/maratona" component={Maratona} />
          <Route exact path="/services/pianificazione/slowtourism" component={Slowtourism} />
          <Route exact path="/services/pianificazione/calcio" component={Calcio} />
          <Route exact path="/services/personal" component={Personal} />
          <Route exact path="/publications" component={Publications} />
          <Route exact path="/services/pilates" component={Pilates} />
          <Route exact path="/services/pilates/ladder" component={Ladder} />
          <Route exact path="/services/pilates/reformer" component={Reformer} />
          <Route exact path="/services/analisi" component={Analisi} />
          <Route exact path="/services/analisi/biaacc" component={Biaacc} />
          <Route exact path="/services/analisi/ppg" component={Ppg} />
          <Route exact path="/services/analisi/plicometria" component={Plicometria} />
          <Route exact path="/theysay" component={Theysay} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/event" component={Event} />
          <Redirect to="/home" />
        </Switch>
        <MenuPage></MenuPage>
        <LastUpdate></LastUpdate>
      </HashRouter>
    </div >
  );
}

export default App;
