import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import ppgstressflow from './ppgstressflow1.jpg'
import ppg1 from './ppg1.jpg'
import ppg2 from './ppg2.jpg'

class Ppg extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              PPG STRESS FLOW BIOTEKNA
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={ppgstressflow} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Il dispositivo “PPG stress flow” analizza con dei sensori posti sulle dita delle mani la funzionalità del sistema nervoso autonomo. Il sistema nervoso autonomo ha due componenti che  lavorano durante la giornata con la propria circadianiatà:
              <br /><br /> Componente ortosimpatica - e’ responsabile nel mantenere l’organismo vigile, aumentando gli ormoni dello stress in caso di “attacco” sia psicologico - come un evento spiacevole -  sia fisico. E’ una componente che deve essere preponderante nella prima parte della giornata, dalla 6 di mattina fino alla prima parte del pomeriggio.
              <br /><br /> Componente parasimpatica - si attiva dal tardo pomeriggio fino alle 4/5 di mattina, dopo un pasto o nei momenti di relax.  Aiuta a raggiungere uno stato di rilassatezza, concilia il sonno e il recupero, permettendo un corretto ripristino delle energie e stimolando il sistema immunitario.
              <br /><br />Detto questo il “PPG stess flow” aiuta a stabilire se e’ presente la corretta circadianita’ valutando la predominanza di una componente rispetto all’altra sapendo, appunto, che dovrà esserci una predominanza simpatica durante la prima parte della giornata.
              <br />In caso di un sistema starato si potranno avere questi sintomi con un calo della qualità di vita:
              <br />•	Effetto di termoregolazione alterato con estremità degli arti freddi.
              <br />•	Acidità e gonfiore di stomaco dopo i pasti.
              <br />•	Stipsi o colon irritabile.
              <br />•	Disturbi del sonno con compromissioni ormonali.
              <br />•	Difficoltà a rilassarsi e a concentrarsi.
              <br />•	Continua ricerca del “confort food” in finestre serali.
              <br />•	Continui stati d’ansia e blocchi emotivi.
              <br /><br /> Con un adeguato stile di vita, consigli alimentari con il timing corretto, piano integrazionale e di fisico-motoria (psicologico e respiratorio) si potrà intervenire sul riequilibrio delle due componenti avendo  dei benefici anche sulla composizione corporea ,riscontrabile con la BIA-ACC.
              <br /><br />Il “PPG stress flow” ha anche  la possibilità di analizzare la propria risonanza respiratoria. In caso la si trovi starata si potrà estrapolare la respirazione con la corretta risonanza dando la possibilità di interagire con il proprio sistema nervoso durante il giorno per mezzo del respiro, migliorando parametri come SDNN (variabilità cardiaca) e RMSSD (efficacia della corteccia prefrontale).
              <br />Il lavoro sul ritmo respiratorio e’ fondato su numerosi studi che dimostrano come si riescano a ridurre i disturbi del sonno, ansia,  tensioni muscolari, pressione arteriosa, aritmie e molte forme d’ansia.
              <br />Dai contesti clinici a quelli sportivi, arrivando alla psicologia clinica, l’analisi della variabilità della frequenza cardiaca e degli indicatori dell’attività del sistema nervoso autonomo offre un ausilio di rilievo in una crescente varietà di applicazioni:
              <br />•	Monitoraggio della sindrome generale di adattamento e dei disturbi correlati allo stress;
              <br />•	Diagnostica e monitoraggio dei disturbi infiammatori cronici e delle patologie autoimmuni;
              <br />•	Analisi delle espressioni psicologiche e delle emozioni;
              <br />•	Aumento della performance sportiva;
              <br />•	Longevità e performance psicofisica.
              <br /><br />La versione base del dispositivo medico “PPG Stress Flow” è in grado di valutare gli aspetti riportati di seguito:
              <br />•	Mean HR: frequenza cardiaca media;
              <br />•	SDNN: Standard Deviation Normal to Normal: indice clinico della variabilità cardiaca di tutto il sistema nervosa autonomo;
              <br />•	RMSSD: indice clinico della variabilità cardiaca del sistema parasimpatico;
              <br />•	Scatter Area:grafico della variabilità cardiaca misurata in ms2;
              <br />•	Total Power: efficienza del sistema nervoso autonomo;
              <br />•	VLF Power: interferenza emotiva sul vago,riguarda la componente simpatica;
              <br />•	LF Power: indicatore dell’attività simpatica;
              <br />•	HF Power: indicatore dell’attività parasimpatica;
              <br />•	Bilateral flow: rappresentazione multicanale del flusso sanguigno;
              <br />•	ANS Balance: rappresentazione della relazione fra attivazione di sistema simpatico e parasimpatico.
              <br /><br />Esempio dati “PPG stress flow” per la valutazione del sistema nervoso autonomo:
        </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={ppg1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={ppg2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Ppg

