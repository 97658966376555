import React from 'react';
import { MDBBtn, MDBIcon } from 'mdbreact'
import './MenuPage.scss'
// import menuData from './menuData'
import { connect } from 'react-redux'
import { hide, toggle } from '../store/actions'
import { Route, HashRouter } from 'react-router-dom'

class MenuPage extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickBars = this.handleClickBars.bind(this)
    this.handleClickHome = this.handleClickHome.bind(this)
    this.handleClickAbout = this.handleClickAbout.bind(this)
    this.handleClickServices = this.handleClickServices.bind(this)
    this.handleClickExperiences = this.handleClickExperiences.bind(this)
    this.handleClickEvent = this.handleClickEvent.bind(this)
    this.handleClickPublications = this.handleClickPublications.bind(this)
    this.handleClickTheysay = this.handleClickTheysay.bind(this)
  }

  handleClickBars(e) {
    this.props.toggle()
  }
  handleClickHome(e, history) {
    this.handleClickBars(e)
    history.push('/home')
  }
  handleClickAbout(e, history) {
    this.handleClickBars(e)
    history.push('/about')
  }
  handleClickServices(e, history) {
    this.handleClickBars(e)
    history.push('/services')
  }
  handleClickExperiences(e, history) {
    this.handleClickBars(e)
    history.push('/experiences')
  }
  handleClickPublications(e, history) {
    this.handleClickBars(e)
    history.push('/publications')
  }
  handleClickTheysay(e, history) {
    this.handleClickBars(e)
    history.push('/theysay')
  }
  handleClickContacts(e, history) {
    this.handleClickBars(e)
    history.push('/contacts')
  }
  handleClickEvent(e, history) {
    this.handleClickBars(e)
    history.push('/event')
  }

  render() {
    return (
      <div>
        <Route render={({ history }) => (<div className={
          'menu-page ' +
          (this.props.menuOpen ? 'menuopen' : 'menuclose')
        }>
          <header>
            <MDBBtn color="elegant" className="topbar-button" onClick={(e => this.handleClickBars(e))}>
              <MDBIcon icon="times" className="fa-3x" />
            </MDBBtn>
          </header>
          <section>
            <div onClick={(e => this.handleClickHome(e, history))} >
              <MDBIcon icon="home" className="ml-2" />
              <label>HOME</label>
            </div>
            <div onClick={(e => this.handleClickServices(e, history))}>
              <MDBIcon icon="project-diagram" className="ml-2" />
              <label>SERVIZI</label>
            </div>
            <div onClick={(e => this.handleClickExperiences(e, history))}>
              <MDBIcon icon="medal" className="ml-2" />
              <label>CERTIFICAZIONI</label>
            </div>
            <div onClick={(e => this.handleClickPublications(e, history))}>
              <MDBIcon icon="info-circle" className="ml-2" />
              <label>DIVULGAZIONE</label>
            </div>
            <div onClick={(e => this.handleClickAbout(e, history))}>
              <MDBIcon icon="user" className="ml-2" />
              <label>CHI SONO</label>
            </div>
            <div onClick={(e => this.handleClickTheysay(e, history))}>
              <MDBIcon icon="id-badge" className="ml-2" />
              <label>DICONO DI ME</label>
            </div>
            <div onClick={(e => this.handleClickContacts(e, history))}>
              <MDBIcon icon="mail-bulk" className="ml-2" />
              <label>CONTATTI</label>
            </div>
          </section>
        </div>
        )} />


        <div className={
          'screen ' +
          (this.props.menuOpen ? 'screenopen' : 'screenclose')
        } onClick={(e => this.handleClickBars(e))}>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuOpen: state.menuOpen
  }
}

const mapDispatchToProps = {
  hide, toggle
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage)