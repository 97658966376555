import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'
import cer_nsca_cscs from './certs/NSCA_CSCS2.jpg'
import cer_nsca_cpt from './certs/NSCA_CPT1.jpg'

class Nsca extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              NATIONAL STRENGTH AND CONDITIONING ASSOCIATION
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              NSCA significa forza e condizionamento. Questi sono i temi di cui ci occupiamo. In qualità di autorità a livello mondiale in tema di forza e condizionamento,
              sosteniamo e divulghiamo conoscenze basate sulla ricerca e le relative applicazioni pratiche, al fine di migliorare la performance atletica e la forma fisica.
              La NSCA è stata fondata nel 1978 da un gruppo di allenatori della forza che condividevano il desiderio di creare una rete di contatti, collaborare e unificare
              la professione. Attualmente la NSCA si compone di 28.000 professionisti dell'esercizio fisico e di circa 40.000 persone in possesso di un certificato valido
              ottenuto attraverso uno dei nostri programmi di certificazione. Insieme ci adoperiamo per colmare il divario tra scienza e applicazione.
              <br />
              Entrambi i programmi CSCS e NSCA-CPT hanno ottenuto l’accreditamento della Commissione nazionale per gli enti di certificazione (NCCA, National Commission for Certifying Agencies).
              Il programma CSCS ha ottenuto l’accreditamento della NCCA nel 1993 ed è stato il primo programma di certificazione per l’allenamento e il condizionamento della forza accreditato a
              livello nazionale. Nel 1996 l’NSCA-CPT è diventato il primo programma di personal trainer certificato accreditato dalla NCCA. Attraverso il processo di accreditamento, la NSCA ha
              dimostrato la capacità dei programmi CSCS e NSCA-CPT di soddisfare e superare gli standard della NCCA
            </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="https://www.nsca.com/" target="_blank" rel="noopener noreferrer">
              https://www.nsca.com/
            </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <div className="cont-cert">
              <img className="img-fluid mouse" src={cer_nsca_cscs} alt=""
                onClick={(e => this.handleClick(e, cer_nsca_cscs))} />
              <div className="cer-body">
                <h4 className="cer-title">Strength and Conditioning Specialists</h4>
                <p className="cer-description">
                  I Certified Strength and Conditioning Specialists® (CSCS) allenano atleti di tutte le età e a tutti i livelli,
                  dai dilettanti ai professionisti, al fine di raggiungere la massima prestazione potenziale, riducendo nel contempo al minimo il rischio di infortunio.
                  Gli atleti, dai principianti a quelli di élite, possono raggiungere livelli di performance elevati grazie all'allenamento offerto da professionisti
                  in possesso della certificazione CSCS.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Nsca)