import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import reformer1 from './reformer1.jpg'
import reformer2 from './reformer2.jpg'
import reformer3 from './reformer3.jpg'
import reformer4 from './reformer4.jpg'
import reformer5 from './reformer5.jpg'
import reformer6 from './reformer6.jpg'
import reformer7 from './reformer7.jpg'
import reformer8 from './reformer8.jpg'
import reformer9 from './reformer9.jpg'
import reformer10 from './reformer10.jpg'

class Ladder extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              REFORMER
            </label>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Il reformer è l’attrezzo più conosciuto del pilates, è molto versatile e offre svariate possibilità di lavoro. Permette di eseguire movimenti di ogni genere: dal più elementare al più difficile.
<br />Il reformer fornisce inoltre la possibilità di monitorare il cliente, essendo in una posizione ideale per correggere gli allineamenti e l’azione muscolare.
<br />Gli allungamenti eseguiti sul reformer per i flessori dell’anca, gli ischiocrurali e gli addominali non potrebbero risultare più efficaci se fossero eseguiti con altri attrezzi.
<br />Il reformer permette una più ampia gamma di esercizi specialmente per la parte alta del corpo.
<br />Con il reformer si possono sviluppare diversi blocchi di lavoro sui vari distretti corporei come:
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Foot work</label>
              <br /> Nonostante la definizione coinvolge tutto il corpo. L’attenzione inizialmente viene posta sul movimento del bacino e della colonna. Il foot work fornisce una grande quantità di informazioni riguardanti la forza, la flessibilità, la stabilizzazione, l’allineamento e lo schema dei movimenti.
              <br />Per molti versi ricalca la nostra andatura, dicendo molto sul nostro modo di camminare e di correre
            </p>
            <img src={reformer1} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Abdominal work</label>
              <br /> Costituisce una parte rilevante del pilates, si lavora sugli obliqui esterni, retto dell’addome, interni e trasverso, con esercizi sia isometrici che isotonici oltre a sequenze che mirano a sviluppare sia la forza che la resistenza muscolare. E’ un lavoro basilare per rinforzare i muscoli del “core” e permettere di affrontare posture dinamiche e statiche durante il giorno in sicurezza, un lavoro formidabile di prevenzione al mal di schiena.
            </p>
            <img src={reformer2} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Hip work</label>
              <br /> Questo blocco di esercizi si concentra sull’articolazione dell’anca dato che c’e’
              una stretta relazione con il bacino, che a sua volta influisce su tutto il corpo. Non sempre i muscoli che agiscono sull’articolazione dell’anca sono bilanciati: lo stile di vita moderno ci costringe per molto tempo alla posizione seduta, creando accorciamento sui flessori dell’anca, indebolimento del medio gluteo che dovrebbe sostenere l’anca e allineare il bacino. Il lavoro mirato sull’anca con il reformer aiuta a portare equilibrio in questa zona molto sollecitata dalla vita di tutti i giorni.
            </p>
            <img src={reformer3} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Spinal articulation</label>
              <br /> E' mirato alla flessibilità della colonna, anche con un lavoro dedicato alla respirazione, per dare un ritmo all’esecuzione dell’esercizio, ma anche per contribuire al movimento dei muscoli giusti.
            </p>
            <img src={reformer4} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Stretching</label>
              <br /> Potendo sfruttare lo scorrimento del carrello si può coinvolgere negli allungamenti i flessori dell’anca e gli ischiocrurali - muscoli spesso rigidi che portano problemi alla bassa schiena.
            </p>
            <img src={reformer5} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Full body integration</label>
              <br /> Questo blocco è il più rappresentativo della filosofia del pilates, in quanto prende in considerazione l’intero corpo che lavora come una macchina integrata, perfettamente lubrificata e calibrata.
            </p>
            <img src={reformer6} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Arm work</label>
              <br /> Questo blocco si concentra sul lavoro della parte alta del corpo. Elemento fondamentale e’, ovviamente, la spalla, l’articolazione più mobile e quindi la più instabile. Senza una meccanica corretta si possono verificare problemi alla spalla, collo e schiena.
<br />Nel caso della spalla e’ la scapola a fornire il supporto mentre il braccio si muove liberamente: il lavoro sulla parte superiore si concentra soprattutto su questo concetto.
            </p>
            <img src={reformer7} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Leg work</label>
              <br /> Questo blocco di esercizi rappresenta un supplemento del foot work. E’dedicato alle gambe e si può individualizzare il lavoro sia che si tratti di calciatori,  ballerini, podisti, ciclisti o chiunque voglia semplicemente mantenere la forma.
            </p>
            <img src={reformer8} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Flessione e rotazione laterale</label>
              <br /> Ci si concentra su questi due movimenti con l’aiuto del reformer perché entrambi sono elementi importanti sia nelle normali attività quotidiane che in quelle sportive. Purtroppo spesso vengono completamente trascurate, creando un “anello debole” della catena causando infortuni o infiammazioni e portando, a volte, al fermo dell’attività sportiva o lavorativa. Senza flessione e rotazione laterale l’allenamento degli addominali non è completo e questo può comportare prestazioni di livello inferiore, oltre a causare disturbi e infortuni.
              <br />Allenare gli addominali in modo completo vuol dire contribuire ad attivare il meccanismo di stabilizzazione e di protezione della colonna.
            </p>
            <img src={reformer9} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <p className="text-justify  white-text">
              •	<label className="font-weight-bold">Estensione della schiena</label>
              <br /> Un lavoro sull’estensione della schiena rende completo il lavoro sul “core”, considerando che con l’età si tende a perdere la capacità di estensione del tronco.
<br /> Lo stile di vita moderno, unitamente alla forza gravitazionale e alla naturale propensione della colonna di curvarsi in avanti, predispongono il nostro corpo ad una postura curva e alla perdita di questa capacità di movimento.
<br />Spesso ci si preoccupa degli addominali a scapito dell’estensione della colonna, e il rapporto tra il lavoro sugli addominali e quello sull’estensione della schiena non è mai proporzionato. Non dobbiamo dimenticare che il nostro corpo si regge grazie agli estensori ed anche se il supporto degli addominali è certamente prezioso sono gli estensori a svolgere il lavoro primario. Grazie al reformer si riescono a compiere degli esercizi funzionali che riprodurranno i movimenti di tutti i giorni.
            </p>
            <img src={reformer10} className="img-fluid" alt="" />
            <br /><br /><br />
          </MDBCol>
        </MDBRow>


      </MDBContainer>
      <br />
    </div>
  }
}

export default Ladder

