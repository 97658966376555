import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'
import cer_open1 from './certs/OPEN_ACADEMY1.jpg'
import cer_open2 from './certs/OPEN_ACADEMY2.jpg'

class Open extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              OPEN ACADEMY
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              L'Open Academy of Medicine è una scuola internazionale di specializzazione e formazione continua in medicina che focalizza la propria attività didattica e di ricerca attorno ad aree di interesse cardinali quali Neuroimmunomodulazione, Metabolismo, Nutrizione Clinica e Riabilitazione Fisico-Motoria.
            </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="http://www.openacademy.me/" target="_blank" rel="noopener noreferrer">
              http://www.openacademy.me/
            </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_open1} alt=""
                onClick={(e => this.handleClick(e, cer_open1))} />
            </div>
          </MDBCol>
          <MDBCol sm="12" lg="6">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_open2} alt=""
                onClick={(e => this.handleClick(e, cer_open2))} />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Open)