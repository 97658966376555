import React from 'react'
import { connect } from 'react-redux'
import '../Whole.scss'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import { toggleModal, changeModalImage } from '../store/actions'
import cer_nsc from './certs/NCS1.jpg'

class Ncs extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, img) => {
    this.props.changeModalImage(img)
    this.props.toggleModal()
  }

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              NEUROCOMSCIENCE
        </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify white-text">
              NeuroComScience è il centro di consulenza, formazione e ricerca in  analisi comportamentale, con una spiccata specializzazione in analisi del comportamento non verbale.  Attualmente NeuroComScience è il  leader in Italia per la lettura del comportamento non verbale: vanta di un team composto dai maggiori esperti nel settore, la più numerosa community di analisti comportamentali specializzati nel non verbale ed esegue il maggior numero di consulenze nel settore. NeuroComScience opera anche in Belgio, Francia, Svizzera, Spagna, Germania.
              MASTER IN ANALISI COMPORTAMENTALE.TECNICHE E METODI SCIENTIFICI DI ANALISI VERBALE E NON VERBALE
              un percorso di specializzazione per apprendere tecniche e metodi scientifici di analisi comportamentale verbale e non verbale.Si tratta di una serie di conoscenze professionali per classificare i comportamenti ed estrapolare con attendibilita' l'interpretazione.
            </p>
            <br />
            <br />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" className="text-center">
            <a className="text-default"
              href="https://lab-ncs.com/" target="_blank" rel="noopener noreferrer">
              https://lab-ncs.com/
          </a>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <div className="cont-cert-no-descr">
              <img className="img-fluid mouse" src={cer_nsc} alt=""
                onClick={(e => this.handleClick(e, cer_nsc))} />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  }
}

const mapDispatchToProps = {
  toggleModal,
  changeModalImage
}

export default connect(null, mapDispatchToProps)(Ncs)