export const SHOW = "SHOW";
export const HIDE = "HIDE";
export const TOGGLE = "TOGGLE"
export const CHANGE_LANGUAGE_EN = "CHANGE_LANGUAGE_EN"
export const CHANGE_LANGUAGE_IT = "CHANGE_LANGUAGE_IT"
export const SHOW_LANGUAGE_PANEL = "SHOW_LANGUAGE_PANEL"
export const HIDE_LANGUAGE_PANEL = "HIDE_LANGUAGE_PANEL"
export const TOGGLE_MODAL = "TOGGLE_MODAL"
export const CHANGE_MODAL_IMAGE = "CHANGE_MODAL_IMAGE"
export const HIDE_LAST_UPDATE = "HIDE_LAST_UPDATE"
export const TOGGLE_SUBMENU_CERT = "TOGGLE_SUBMENU_CERT"
export const SHOW_SUBMENU_CERT = "SHOW_SUBMENU_CERT"
export const HIDE_SUBMENU_CERT = "HIDE_SUBMENU_CERT"
export const TOGGLE_SUBMENU_SERV = "TOGGLE_SUBMENU_SERV"
export const SHOW_SUBMENU_SERV = "SHOW_SUBMENU_SERV"
export const HIDE_SUBMENU_SERV = "HIDE_SUBMENU_SERV"

export const show = () => ({ type: SHOW })
export const hide = () => ({ type: HIDE })
export const toggle = () => ({ type: TOGGLE })
export const changeLanguageEN = () => ({ type: CHANGE_LANGUAGE_EN })
export const changeLanguageIT = () => ({ type: CHANGE_LANGUAGE_IT })
export const showLanguagePanel = () => ({ type: SHOW_LANGUAGE_PANEL })
export const hideLanguagePanel = () => ({ type: HIDE_LANGUAGE_PANEL })
export const toggleModal = () => ({ type: TOGGLE_MODAL })
export const changeModalImage = (image) => ({ type: CHANGE_MODAL_IMAGE, image })
export const hideLastUpdate = () => ({ type: HIDE_LAST_UPDATE })
export const toggleSubmenuCert = () => ({ type: TOGGLE_SUBMENU_CERT })
export const showSubmenuCert = () => ({ type: SHOW_SUBMENU_CERT })
export const hideSubmenuCert = () => ({ type: HIDE_SUBMENU_CERT })
export const toggleSubmenuServ = () => ({ type: TOGGLE_SUBMENU_SERV })
export const showSubmenuServ = () => ({ type: SHOW_SUBMENU_SERV })
export const hideSubmenuServ = () => ({ type: HIDE_SUBMENU_SERV })
