import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import piano_annuale_calcio from './piano_annuale_calcio_juniores.jpg'

class Calcio extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Calcio Under 15
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Ho deciso di creare questo servizio specifico per il calcio (ma che è possibile sviluppare per qualsiasi sport di squadra), per migliorare sia la tecnica di corsa che la performance degli atleti juniores.
<br />Come si può vedere dalla pianificazione sotto esposta, la preparazione inizia a giugno in off-season, cioè a campionato finito, potendo così lavorare su quelle componenti che in stagione peggiorerebbero la condizione prestativa dell’atleta.
<br />Guardando le partite dei ragazzi spesso mi accorgo del grande potenziale in loro possesso, pur avendo una tecnica di corsa scadente.
<br />La pianificazione sotto esposta vuole solo essere un esempio per illustrare, a grandi linee, come sarà distribuito il lavoro nell’arco dei 4 mesi, pianificando la giusta alternanza delle abilità biomotorie (forza-velocità-resistenza) e dei fattori limitanti nel calcio (potenza di partenza, potenza reattiva, di accelerazione e di decelerazione). Non bisogna dimenticare che se i fattori limitanti non verranno sviluppati in preparazione, la prestazione non sarà ottimale.
<br />Come e’ possibile vedere nella pianificazione è presente uno sviluppo dei sistemi energetici dominanti (aerobico, anaerobico lattacido, anaerobico alattacido) che permetteranno ai ragazzi di iniziare la stagione competitiva con una capacità e potenza aerobica già elevata e con una tecnica di corsa corretta ed efficace, potendo concentrarsi su altri componenti tecnici e tattici.
<br />Si potrà lavorare a gruppi di 5/8 ragazzi per 3 volte a settimana. I test segnati mi permetteranno di individualizzare la preparazione sui punti deboli di ognuno. Gli allenamenti si svolgeranno all’aperto - a corpo libero - e con l‘utilizzo di attrezzi ginnici come corda per saltare, over per i salti, cinesini per lavori di agilità, accelerazioni e decelerazioni, palle mediche ed elastici per l’innalzamento della forza del “core” e della parte superiore.
      </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <img src={piano_annuale_calcio} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              MODELLO PRESTATIVO:
              <br />sistema energetico dominante: aerobico, anaerobico alattacidi, anaerobico lattacido
              <br />ergogenesi: 2%anaerobico alattacido, 23%anaerobico lattacido, 75%aerobico
              <br />substrati energetici: fosfocreatina,glicogeno
              <br />fattori limitanti: potenza reattiva, potenza di partenza,potenza di accelerazione e di decelerazione,potenza resistente breve
              <br />obiettivi dell’allenamento: potenza, forza massima, capacità aerobica.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br />
    </div>
  }
}

export default Calcio
