import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import biaacc from './biaacc1.jpg'
import biaacc_body from './biaacc_body.jpg'
import biaacc3 from './biaacc3.jpg'
import biaacc4 from './biaacc4.jpg'
import biaacc5 from './biaacc5.jpg'

class Biaacc extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              BIA ACC BIOTEKNA
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">

            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={biaacc} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Con la bioimpedenza (BIA) è possibile avere un esame tri-compartimentale (idratazione, percentuale di massa grassa e percentuale di massa magra) della composizione corporea. Si tratta, quindi, di esame qualitativo, a differenza della plicometria che è un esame bi-compartimentale- quindi quantitativo - (percentuale di massa grassa e percentuale di massa magra).
            <br />Un soggetto misurato con la BIA, rispetto alla misurazione plicometrica, ha risultati completamente differenti proprio perché la plicometria stima solo il grasso sottocutaneo, mentre la tecnologia bioimpedenziometrica (BIA) calcola il grasso totale: sottocutaneo, viscerale e intramuscolare.
            <br /><br />BIA-ACC BIOTEKNA è un dispositivo ad alta tecnologia che permette di valutare, con estrema precisione e affidabilità, la composizione corporea e, se necessario, indirizzare la persona ad altri specialisti quali biologo,nutrizionista,psicologo,personale sanitario, etc.
              <br />Il dispositivo BIA-ACC BIOTEKNA è stato progettato per semplificare la valutazione, l’analisi e il monitoraggio della composizione corporea nei suoi aspetti quantitativi, qualitativi e funzionali, utilizzando una tecnologia bioimpedenziometrica multifrequenza in grado di massimizzare l’efficienza delle misurazioni anche quando applicata a soggetti affetti da disturbi a carattere infiammatorio cronico o correlati allo stress.
        </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={biaacc_body} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">

              Grazie all’aiuto della BIA-ACC BIOTEKNA possiamo avere una mappa della nostra “centralina” metabolica fornendo dati preziosi riguardanti problematiche come osteoporosi, sarcopenia, infiammazione di basso grado,disidratazione,innalzamento della percentuale di grasso superficiale,viscerale e intramuscolare - con tutte le patologie che può comportare - e una valutazione del metabolismo basale, potendo così intervenire su errori alimentari.
              <br /> Che si lavori con l’obiettivo di aumentare una performance, di ricerca del benessere o di miglioramento estetico, questa analisi permette di raggiungere il massimo del nostro potenziale esaltando le caratteristiche fisiche nel rispetto della salute.
              <br /> La BIA-ACC BIOTEKNA permette quindi un monitoraggio minuzioso dando la possibilità di stilare un piano personalizzato di allenamento,integrazione,stile di vita e  consigli alimentari adeguati,consentendo alle persone di capire e comprendere il funzionamento del corpo umano, insegnando loro ad autogestirsi per arrivare all’obiettivo di piena salute.
              <br />
              <br />Il dispositivo è in grado di rilevare i parametri riportati di seguito:
              <br />•	TBW, Total Body Water: acqua corporea totale.
              <br />•	ECW, ExtraCellular Water: acqua nell’ambiente extracellulare.
              <br />•	ICW, IntraCellular Water: acqua nell’ambiente intracellulare.
              <br />•	FFM, Fat Free Mass: massa magra.
              <br />•	FM, Fat Mass: massa grassa.
              <br />•	ECMatrix, ExtraCellular Matrix: matrice extracellulare.
              <br />•	BMR, Basal Metabolic Rate: dispendio energetico in condizioni di massimo riposo fisico e mentale, a digiuno da dodici ore.
              <br />•	HPA Axis Index: indice dell’andamento circadiano dell’espressione degli ormoni dello stress.
              <br />


              <br />Approfondendo, scopriamo come un esame impedenziometrico può aiutarci nello stabilire oggettivamente la presenza o meno di processi infiammatori e di stress cronico aiutandoci a programmare gli  allenamenti con dei follow-up cadenzati, dandoci la certezza di essere sulla strada giusta o intervenendo senza perdere tempo in caso l’analisi non dimostri un miglioramento.
              <br /><br />Una condizione di stress cronico (persistenza dello stressor oltre i sei mesi) impatta sul nostro organismo, cambiandone la composizione corporea. Ad esempio, il metabolismo cambia nel tempo soprattutto quando si vive una situazione di stress costante.
              <br /><br />Cambia anche la densità delle fibre muscolari (S-score), che si indeboliscono, così come la struttura ossea (T-score). Sia i muscoli che le ossa, con una situazione di stress che dura da molto tempo, divengono dei fornitori di energia, dato che si ha una sovrattivazione sistemica. Si arriva ad un punto in cui, però, tali strutture vengono compromesse e si deve intervenire per ristabilirne un giusto equilibrio quantitativo e qualitativo.
              <br /><br /> Un quadro del genere porta a  stanchezza cronica, affaticabilità, poca voglia di muoversi, difficoltà a perdere grasso, apatia, si modifica il respiro, malinconia, ritiro sociale (infatti, il metabolismo dopo un lungo periodo di stress e di dispendio energetico, si pone in una condizione di risparmio, in quanto le strutture che forniscono energia sono compromesse).
              <br /><br />Un dato estremamente importante che porta capire che si e’ in uno stato di infiammazione e stress è la  presenza elevata di acqua extracelluare (ECW). Valutando questo dato  e, correlandolo con ECMatrix e asse HPA, si potrà intervenire – con dei follow-up cadenziati - sul volume e intensità dell’allenamento,sullo stile di vita,sull’alimentazione e integrazione.
              <br /><br />Un’alimentazione non adeguata e uno stile di vita malsano possono portare all’aumento  di grasso infiltrato nei muscoli (IMAT), al grasso viscerale che viene a depositarsi attorno agli organi interni, al grasso bianco non utilizzabile come fonte energetica, al potassio extracelluare (ECK), ad un elevato valore della matrice extracellulare (ECMatrix). Sono tutti dati direttamente collegati che rappresentano un’infiammazione costante di basso grado,scarsa capacità antinfiammatoria dell’organismo e vari sintomi di tipo fisico e psicologico. La riduzione della funzione antinfiammatoria del nostro corpo ha un impatto molto rischioso in quanto siamo indifesi rispetto a malattie o traumi.
              <br /><br />Altro fattore fondamentale nella valutazione dello stress cronico, è l’asse HPA, ovvero il sistema ipotalamo-ipofisi-surrene. Tale asse, in condizione di stress, viene stimolato e provvede ad aumentare il rilascio degli ormoni dello stress (glutocorticoidi, cortisolo). Quando tale attivazione rimane costante, come avviene quando lo stress si cronicizza, il rilascio di tali ormoni rimane costantemente attivato, anche di notte, con numerose ricadute negative per il nostro organismo, corpo-cervello-mente. Grazie all’esame BIA-ACC BIOTEKNA è possibile valutare il funzionamento dell’asse HPA tramite il dato che emerge dell’angolo di fase che, se inferiore a 3,5 indica un rilascio costante di cortisolo, quindi un circuito di risposta allo stress perennemente attivato. In condizioni di normalità invece, il rilascio ormonale segue una sua circadianità con un picco al mattino, ed un progressivo decremento durante la giornata, fino ad arrestarsi durante la notte. Lo stress cronico, invece, rompe l’equilibrio circadiano con conseguenze negative per il nostro corpo.
              <br /><br />Esistono altri parametri che emergono dall’esame della BIA-ACC BIOTEKNA che consentono di entrare nello specifico del quadro diagnostico di una persona. In questo modo, è possibile costruire un programma di intervento personalizzato e finalizzato a ripristinare l’equilibrio compromesso.
              <br /><br />Esempio dati BIA-ACC per la valutazione dello stress cronico e di infiammazione di basso grado:
</p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={biaacc3} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={biaacc4} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12">
            <p className="text-justify  white-text">
              OSO Test - quadro rappresentativo e riassuntivo della condizione del paziente ricavato dal test BIA
            </p>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol md="12" className="text-center">
            <img src={biaacc5} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="white-text">
              <br />IN CHE SITUAZIONI POSSO USARE BIA-ACC BIOTEKNA?
            <br />
              <br />•	anti-aging
              <br />•	per valutare l’andamento della pianificazione annuale di allenamento
              <br />•	per problemi di sindrome metabolica
              <br />•	per un piano di allenamento con obiettivo  dimagrimento o aumento massa magra
              <br />•	per una correzione sullo stile di vita
              <br />•	pre-post gara/allenamento. ci dà una visione sulla capacità di recupero fisico aiutando a programmare il giusto periodo di scarico prima di una gara importante o il recupero dopo un periodo di allenamento stressante
            </p>
          </MDBCol>
        </MDBRow>





        <br />


      </MDBContainer>
      <br />
    </div>
  }
}

export default Biaacc

