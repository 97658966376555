import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'

class Nordic extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Nordic
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <p className="text-justify white-text">
              Il nordic walking e’ una disciplina nata come allenamento per gli atleti di sci di fondo negli anni 30 in Finlandia.
              <br />Dal 1999 si e’ espansa anche in altre nazioni fino ad arrivare in Italia.
              <br />E’ un attività che si pratica con appositi bastoncini che permettono di utilizzare il 90% della muscolatura.
              <br />La tecnica e’ composta da 8 fasi; il gran dorsale e il tricipite sono i muscoli che permettono di spingere il corpo in avanti dando la possibilità alla parte superiore di lavorare durante la camminata.
              <br />Questi due muscoli migliorano la postura agendo sull’abbassamento delle spalle, portandole indietro.
              <br />Gli altri vantaggi della camminata nordica sono: miglioramento della capacità aerobica, miglioramento della coordinazione, maggior dispendio calorico rispetto alla camminata normale e minor carico sulle articolazioni, soprattutto nei tratti in discesa.
              <br />Con due sedute da 120’ è possibile imparare la tecnica per gestire poi le camminate in completa autonomia. Verranno inoltre insegnate delle tecniche allenanti per favorire il miglioramento della capacità e della potenza aerobica, che permetteranno con un corretto stile di vita di tenere sotto controllo il peso corporeo. Un altro valido motivo per imparare la tecnica del nordic walking e’ che si può utilizzare nello “slow tourism” con tappe anche di 20-30 km al giorno, rendendo la camminata stessa più economica e facendoci risparmiare energie.
            </p>
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Nordic