import React from 'react'
import { MDBRow, MDBContainer, MDBCol } from 'mdbreact'
import piano_annuale_preparazione_sci from './piano_annuale_preparazione_sci.jpg'
import sky1 from './sky_1.jpg'
import sky2 from './sky_2.jpg'

class Sci extends React.Component {

  render() {
    return <div className="background">
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12">
            <label className="title text-center white-text pt-4">
              Sci
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              Come nelle altre discipline anche - e soprattutto nello sci - si programma un piano annuale come quello sotto esposto della durata minima di 4/6 mesi fino alla riapertura degli impianti sciistici dove si andrà a lavorare inizialmente sull’adattamento anatomico nella sub fase di preparazione generale. L’obiettivo è di abituare il corpo al carico fisico, allenare tendini e legamenti allo sforzo e creare, al tempo stesso, una base muscolare alle gambe e al “core” (addominali-lombari-glutei-flessori dell’anca…) per poter poi affrontare nella sub fase di preparazione specifica la forza, la potenza e la resistenza alla potenza, utilizzando anche mezzi allenanti specifici come lo “skier’s edge” e il “bosu” visibili e descritti sotto.
<br />Inoltre sarà possibile organizzare un corso di presciistica - con un massimo di 10 partecipanti - con l’utilizzo dei bosu, con l’obiettivo di innalzare la prestazione e abbassare il rischio di infortuni per l’apertura degli impianti i primi di dicembre.
      </p>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={sky1} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <p className="text-justify white-text">
              Skier’s edge è un innovativo attrezzo proveniente dagli Stati Uniti che simula il gesto tecnico dello sci alpino.
<br />E’ utilizzato da molte nazionali di sci per la preparazione dei propri atleti. In America viene usato nei centri di riabilitazione e nelle grandi università, ed in sport come basket, hockey e football americano. Fornisce un gran lavoro pliometrico senza però creare nessun tipo di trauma. Con le sue regolazioni consente di riprodurre qualsiasi tipo di sciata, creando un’instabilità del corpo e migliorando al tempo stesso: forza, potenza, agilità, equilibrio, tecnica, resistenza e potenza aerobica, stimando un consumo calorico di oltre 1000 calorie all’ora. Utilizzando skier’s edge con pesi e bosu si arriverà ad un inizio di stagione sciistica con prestazioni atletiche superiori. Si avrà la possibilità di fare delle ore private sia a gruppi di massimo tre persone sia singolarmente.
        </p>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={sky2} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <p className="text-justify white-text">
              BOSU è adatto a tutti, anche a coloro che soffrono di posture scorrette.
<br />E’ un attrezzo che ammortizza l’impatto del piede ed è indicato nella riabilitazione post traumatica soprattutto di ginocchio e caviglia. E' un acronimo che significa “su entrambi i lati”. Migliora l’equilibrio e la stabilità poggiando a terra sia la piattaforma che la cupola. E’ adatto sia alle persone poco allenate che agli atleti.
<br />Si lavora con il BOSU con l’obiettivo di sviluppare e mantenere le capacità biomotorie sopra esposte, lavorando sui distretti muscolari (add/gambe/glutei) più utilizzati in discipline come: sci, snowboard, pattinaggio a rotelle e su ghiaccio, hockey su ghiaccio e kitesurfing.
        </p>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
        <MDBRow>
          <MDBCol md="12">
            <img src={piano_annuale_preparazione_sci} className="img-fluid" alt="" />
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol sm="12">
            <p className="text-justify  white-text">
              MODELLO PRESTATIVO:
              <br />sistema energetico dominante: anaerobico lattacido,anaerobico alattacido
              <br />egogenesi: 10%anaerobico alattacido, 40%anaerobico lattacido, 50%aerobico
              <br />substrati energetici principali: fosfocreatina,glicogeno
              <br />fattori limitanti: potenza reattiva,potenza resistente
              <br />obiettivi dell’allenamento: potenza resistente,resistenza muscolare di breve durata,forza massima
            </p>
          </MDBCol>
        </MDBRow>
        <br />
      </MDBContainer>
      <br />
    </div>
  }
}

export default Sci

